section.title-subtitle-section {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        & > h3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $woodsmoke;
            font-family: Inter;
            font-size: 35px;
            letter-spacing: 0;
            line-height: 46px;
            text-align: center;
            @media (max-width: 490px) {
                font-size: 20px;
                line-height: 28px;
            }
        }
        .title-subtitle-section__subtitle {
            font-weight: 400;
            font-family: Inter;
            font-size: 46px;
            letter-spacing: 0;
            line-height: 65px;
            @media (max-width: 490px) {
                font-size: 18px;
                letter-spacing: 0;
                line-height: 28px;
            }
        }
    }
}
.Schulungen {
    section.flexible-title-section {
        @media (max-width: 490px) {
            padding-bottom: 15px !important;
        }
    }
}
