@include font-face("Inter-Black", "Inter-Black", 400);
@include font-face("Inter-Medium", "Inter-Medium", 400);
@include font-face("Inter-Bold", "Inter-Bold", 400);
@include font-face("Inter-Regular", "Inter-Regular", 400);
@include font-face("Inter-SemiBold", "Inter-SemiBold", 400);
@include font-face("Inter-Thin", "Inter-Thin", 400);
@include font-face("Roboto-Black", "Roboto-Black", 400);
@include font-face("Roboto-BlackItalic", "Roboto-BlackItalic", 400);
@include font-face("Roboto-Bold", "Roboto-Bold", 400);
@include font-face("Inter-ExtraBold", "Inter-ExtraBold", 400);
@include font-face("Roboto-Italic", "Roboto-Italic", 400);
@include font-face("Roboto-Light", "Roboto-Light", 400);
@include font-face("Roboto-LightItalic", "Roboto-LightItalic", 400);
@include font-face("Inter-Light", "Inter-Light", 400);
@include font-face("Roboto-BoldItalic", "Roboto-BoldItalic", 400);
@include font-face("Inter-ExtraLight", "Inter-ExtraLight", 400);
@include font-face("Roboto-Medium", "Roboto-Medium", 400);
@include font-face("Roboto-MediumItalic", "Roboto-MediumItalic", 400);
@include font-face("Roboto-Regular", "Roboto-Regular", 400);
@include font-face("open-sans-semibold", "open-sans-semibold", 400);
@include font-face("Roboto-Thin", "Roboto-Thin", 400);
@include font-face("Roboto-ThinItalic", "Roboto-ThinItalic", 400);
