section.content-page-section {
    padding-top: 10px;
    padding-bottom: 81px;
    @media (max-width: 490px) {
        padding-bottom: 30px;
    }
    @media (max-width: 1024px) {
        padding-bottom: 30px;
    }
    .container {
        & > p,
        p {
            font-family: Inter;
            // font-size: 16px;
            // letter-spacing: 0;
            // line-height: 30px;
            color: $woodsmoke;
            @media (max-width: 1750px) {
                // font-size: 16px;
                // letter-spacing: 0;
                // line-height: 30px;
                padding-bottom: 10px;
            }
            // @media (max-width: 890px) {
            //     font-size: 14px;
            //     line-height: 28px;
            // }
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            padding-bottom: 20px;
        }
        ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 30px;
            & > li {
                position: relative;
                padding-left: 20px;
                padding-bottom: 20px;
                @media (max-width: 490px) {
                    padding-bottom: 0px;
                }
                @media (max-width: 1750px) {
                    font-size: 16px;
                    padding-left: 20px;
                }
                &::before {
                    position: absolute;
                    left: 0;
                    top: 12px;
                    content: "";
                    height: 8px;
                    width: 8px;
                    background-color: $black;
                    border-radius: 50%;
                }
            }
        }
        ol {
            margin-left: 40px;
            li {
                list-style: auto;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 30px;
                @media (max-width: 890px) {
                    font-size: 14px;
                    line-height: 28px;
                }
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                &::marker {
                    font-weight: 500;
                }
            }
        }
    }
}
.Schulungen section.content-page-section {
    & > .container {
        & > p {
            @media (max-width: 490px) {
                padding-left: 0px !important;
                font-size: 14px;
                line-height: 26px;
            }
        }
    }
}
section.alerting-solution-section {
    // margin-bottom: 20px;
    margin-top: 130px;
    @media (max-width: 490px) {
        margin-bottom: 40px;
        margin-top: 40px;
    }
    .container {
        display: flex;
        flex-direction: column;
        & > h3 {
            max-width: 1066px;
            padding-bottom: 55px;
            text-align: center;
            margin: 0 auto;
            @media (max-width: 1250px) {
                padding-bottom: 50px;
            }
            @media (max-width: 890px) {
                padding-bottom: 40px;
            }
            @media (max-width: 490px) {
                padding-bottom: 25px;
            }
        }
        .alerting-solution {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            margin-left: -2rem;
            margin-bottom: -2rem;
            @media (max-width: 961px) {
                flex-direction: column !important;
                align-items: center;
                margin-left: 0;
                margin-bottom: 50px;
            }
            &__img {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 2rem;
                margin-bottom: 2rem;
                width: calc(1 / 2 * 100% - 2rem);
                min-width: 450px;
                @media (max-width: 961px) {
                    min-width: 300px;
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 1rem;
                }
                & > img {
                    width: 100%;
                    object-fit: contain;
                    @media (max-width: 1250px) {
                        max-height: 380px;
                        width: 100%;
                    }
                    @media (max-width: 490px) {
                        max-width: 290px;
                        object-fit: contain;
                        max-height: 230px;
                    }
                }
            }
            &__content {
                max-width: 600px;
                color: $woodsmoke;
                font-family: Inter;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 30px;
                margin-left: 2rem;
                margin-bottom: 2rem;
                width: calc(1 / 2 * 100% - 2rem);
                min-width: 450px;
                @media (max-width: 961px) {
                    min-width: 270px;
                    margin-left: 0rem;
                    margin-bottom: 0rem;
                    width: 100%;
                }
                p {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                    @media (max-width: 490px) {
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 26px;
                    }
                }
                a {
                    color: $primary;
                    text-decoration: none;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    li {
                        position: relative;
                        padding-left: 20px;
                        font-family: Inter;
                        font-size: 23px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 33px;
                        padding-bottom: 20px;
                        &::before {
                            position: absolute;
                            content: "";
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: $primary;
                            left: 0;
                            top: 12px;
                        }
                        span {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}
section.simple-text-section {
    margin-top: 130px;
    margin-bottom: 50px;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > h3 {
            padding-bottom: 42px;
            text-align: center;
        }
        .simple-description {
            max-width: 1140px;
            font-family: Inter;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 42px;
            margin: 0 auto;
            p {
                font-family: Inter;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 42px;
                text-align: center;
                color: $woodsmoke;
            }
        }
    }
}
section.icon-descr-row-section {
    margin-top: 15px;
    margin-bottom: 81px;
    .container {
        & > h3 {
            text-align: center;
            padding-bottom: 40px;
        }
        .description-section-icon {
            padding-bottom: 81px;
        }
        .icon-descr-items {
            display: flex;
            margin-left: -1rem;
            flex-wrap: wrap;
            justify-content: center;
            &__item {
                min-width: 298px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px;
                margin-left: 1rem;
                margin-bottom: 1rem;
                width: calc(1 / 4 * 100% - 1rem);
                &__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                    & > img {
                        width: 100%;
                        object-fit: contain;
                    }
                }
                &__title {
                    & > h4 {
                        font-family: Inter;
                        font-size: 20px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 35px;
                        text-align: center;
                        color: $dark;
                        padding-bottom: 15px;
                    }
                }
                &__descr {
                    p,
                    div,
                    a {
                        font-family: Inter;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 30px;
                        text-align: center;
                    }
                    p {
                        color: $woodsmoke;
                    }
                    a {
                        color: $primary;
                    }
                }
            }
        }
        .btn-smaller {
            margin: 15px 0;
            // font-size: 18px;
            // padding: 15px 32px 16px;
        }
        .btn-smaller-er {
            margin: 5px 0;
            // font-size: 16px;
            // padding: 10px 25px 11px;
        }
        .btn-margin-right{
            margin-right: 1rem;
            @media (max-width: 590px) {
                margin-right: 0;
            }
        }
        .btn-margin-left {
            margin-left: 1rem;
            @media (max-width: 590px) {
                margin-left: 0;
            }
        }
        .buttons-cta {
            display: flex;
            justify-content: center;
            padding-top: 20px;
            @media (max-width: 1024px) {
                flex-wrap: wrap;
                & > a {
                    width: fit-content;
                }
            }
        }
    }
}
.bg-dark-icon-row {
    position: relative;
    padding-top: 100px;
    padding-bottom: 81px;
    background-color: $new_light_blue;
    &::after {
        position: absolute;
        content: url(../img/white_oval_bottom_left.svg);
        left: 0;
        bottom: 0;
    }
    &::before {
        position: absolute;
        content: url(../img/white_oval_top_right.svg);
        top: 0;
        right: 0;
        z-index: 0;
    }
    & > div {
        color: $woodsmoke;
    }
    & > div > h3 {
        color: $woodsmoke;
    }
    & > div > div > div > p {
        color: $woodsmoke !important;
    }
    & > div > div > div > div > p {
        color: $woodsmoke !important;
    }
    & > .container > .icon-descr-items {
        flex: 1;
        flex-grow: 1;
        gap: 20px;
        justify-content: space-evenly !important;
    }
}
section > .news_links > a {
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}
section.two-columns {
    & > section {
        & > div {
            column-count: 2;
            column-gap: 4em;
        }
    }
}
section.two-columns-excerpt {
    & > div {
        column-count: 2;
        column-gap: 4em;
    }
}
