.tut_container {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    margin-top: -60px;
}

.tutorial_container {
    width: 48%;
    padding-bottom: 40px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.tutorial_container h4 {
    font-family: Inter;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 400;
    @media (max-width: 1600px) {
        font-size: 16px;
        line-height: 30px;
    }
    @media (max-width: 590px) {
        font-size: 14px;
        line-height: 28px;
    }
}
.akt_page_title {
    padding-bottom: 90px;
    height: 65px;
    color: #0c0d0e;
    font-size: 55px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 65px;
}
.tutorial_sub {
    padding-top: 200px;
    padding-bottom: 260px;
    text-align: center;
}
.tutorial_sub h4 {
    height: 130px;
    color: #1d1d1e;
    font-size: 46px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 65px;
    text-align: center;
    padding-top: 25px;
}
.tutorial_sub a {
    width: fit-content;
    display: block;
    border-radius: 50px;
    border: solid 1px $primary;
    background-color: $primary;
    color: $white;
    font-family: Inter;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    padding: 22px 45px;
    transition: all 0.3s;
    &:hover {
        background-color: $white;
        color: $primary;
        border: solid 1px $primary;
    }
    &:active {
        background-color: $woodsmoke;
        color: $white;
        border: solid 1px $woodsmoke;
    }
    @media (max-width: 490px) {
        font-size: 18px;
        padding: 18px 32px 19px;
    }
}
// .tutorial_sub a:hover {
//     transform: scale(1.1);
//     transition: 0.4s;
// }
.tutorial_sub p {
    color: #1d1d1e;

    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
}
.tutorial_holder button img {
    margin-left: 10%;
}
.tutorial_holder button {
    background: rgba(235, 38, 56, 0.6);
    width: 126px;
    height: 126px;
    margin-left: calc(50% - 63px);
    border-radius: 50%;
    border: none;
    margin-top: calc(30% - 63px);
}
.tutorial_holder button:hover {
    background: rgba(235, 38, 56, 0.9);
    transform: scale(1.1);
    transition: 0.2s;
}
.tutorial_holder {
    height: 380px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.lightbox {
    background-color: rgba(0, 0, 0, 0.7);
    overflow: scroll;
    position: fixed;
    display: none;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

.lightbox-container {
    position: relative;
    max-width: 960px;
    margin: 7% auto;
    display: block;
    padding: 0 3%;
    height: auto;
    z-index: 10;
}

.lightbox-close {
    text-transform: uppercase;
    background: transparent;
    position: absolute;
    font-weight: 300;
    font-size: 20px;
    display: block;
    border: none;
    color: white;
    top: -22px;
    right: 3%;
}
.lightbox-close:hover {
    font-weight: 300;

    transform: rotate(180deg);
    transition: 0.4s;
}

.video-container {
    padding-bottom: 56.25%;
    position: relative;
    padding-top: 30px;
    overflow: hidden;
    height: 0;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

/* IGNORE FORM THIS POINT ON */

#container {
    border-radius: 4px;
    max-width: 300px;
    height: auto;
    padding: 50px;
    background: white;
    margin: 100px auto;
}

#playme {
    background: #007fed;
    text-transform: uppercase;
    font-weight: 300;
    border: none;
    color: white;
    padding: 10px 15px;
    display: inline-block;
    font-size: 14px;
    margin: 0;
}

.single_webinar_container {
    background: #f7f8f9;
    display: flex;
    padding: 40px;
    margin-bottom: 40px;
}
.webinar_left {
    width: 15%;
}
.event_left {
    width: 30%;
}
.event_construct .title h2 {
    padding-bottom: 30px !important;
}
.event_construct .title-and-descr-section {
    padding-bottom: 85px !important;
}
.event_right {
    width: 65%;
    padding-right: 3%;
}
.webinar_right {
    width: 80%;
    padding-right: 3%;
}
.single_webinar_container a img {
    width: 40px;
}
.single_webinar_container a img:hover {
    transform: scale(1.1);
    transition: 0.2s;
}
.single_webinar_container a {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
}
.single_webinar_container h4 {
    padding-bottom: 15px;
}
.web_content {
    padding-top: 70px;
}
.web_content p {
    padding-left: 113px;
}
.webinar_sub .tutorial_sub {
    padding-top: 50px !important;
}
.events_section {
    padding-bottom: 73px;
}
.promotions_page .card-list__item {
    padding: 40px !important;
}
.promotions_page .card-list__item h4,
.promotions_page .card-list__item__descr,
.promotions_page .card-list__item__link,
.promotions_page .card-list__item__link a {
    text-align: left !important;
    margin-right: auto !important;
    color: #0c0d0e !important;
}
.promotions_page .card-list__item__descr {
    font-size: 22px !important;
    font-weight: 300 !important;
}
.promotions_page .card-list__item__link a {
    font-weight: 300 !important;
    padding-top: 15px;
}
.promotions_page .card-list__item__link > a > i {
    color: #0c0d0e !important;
}
.Schulungen .card-list__item__link {
    display: none;
}
.Schulungen .card-list__item {
    width: calc(50% - 2rem) !important;
    margin-bottom: 2rem !important;
    text-align: left !important;
}
.Schulungen section.card-link-section .container .card-list__item__descr {
    max-width: 100%;
    text-align: left;
}
.Schulungen section.card-link-section {
    padding-bottom: 0 !important;
}
.Schulungen .flexible-title-section {
    padding-bottom: 50px !important;
}
.Schulungen .title-and-descr-section .container {
    padding-bottom: 0 !important;
}
.Schulungen .title-and-descr-section {
    padding-bottom: 50px !important;
}
.Schulungen .content-page-section .container p {
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    padding-left: 150px !important;
}
.Schulungen .card-list__item h4,
.Schulungen .card-list__item {
    text-align: left !important;
    margin-right: auto;
}
section.dark_overlay h1 {
    z-index: 2;
}
.dark_overlay {
    position: relative !important;
}
section.dark_overlay::after {
    content: "";
    position: absolute;
    top: 0;
    height: 54vh;
    z-index: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #062336;
    opacity: 0.6;
}
.wpcf7 .wpcf7-list-item {
    order: revert;
    display: flex !important;
}
.d-block {
    display: block !important;
}
.wpcf7-list-item-label {
    margin-left: 0 !important;
}
.wpcf7-radio {
    display: flex;
    margin-top: 20px;
}
input[type="radio"] {
    margin-top: 4px;
    margin-right: 40px;
    margin-left: 10px;
}
.wpcf7-form-control.wpcf7-radio {
    position: relative;
}
input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #eb2638;
    border-radius: 50%;
    outline: none;
}
input[type="radio"]:before {
    content: "";
    display: block;
    border-color: #eb2638;
    margin: 3px auto;
    border-radius: 50%;
}
input[type="radio"]:checked:before {
    height: 12px;
    width: 12px;
    background-color: #eb2638;
}
#white-paper-name {
    display: none;
}
.form-row div {
    color: #0c0d0e;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
}
.single_whitepaper .form-wrap {
    gap: 20px;
}
.news_links {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 81px;
}
.news_links a {
    color: #eb2638;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-transform: uppercase;
    padding: 5%;
}
#webinar-name,
#veranstaltungen-name {
    display: none !important;
}
.single .content-page-section img,
.single .content-page-section figure {
    max-width: 100%;
    margin: 0;
}
.past {
    display: none;
}
@media screen and (max-width: 576px) {
    section.alerting-solution-section .container .banner-container__item {
        min-width: 100%;
    }
    h1 {
        font-size: 18px;
    }
    section.join-us-section .container > h3 {
        text-align: center;
    }
    .tutorial_sub p {
        word-wrap: break-word;
        color: #1d1d1e;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0;
        max-width: 100%;
        height: fit-content;
        line-height: 42px;
        text-align: center;
    }
    .tutorial_sub h4 {
        height: fit-content;
    }
    .akt_page_title {
        text-align: center;
    }

    .news-list__item {
        min-width: 100%;
    }
    section.contact-form-section .container .contact-form-wrapper {
        padding: 20px;
    }
    section.solution-section .container > h3 {
        font-size: 30px;
        line-height: 40px;
    }
    .webinar_construct section.title-and-descr-section .container .description {
        padding-left: 13% !important;
    }
    .webinar_construct section.title-and-descr-section .container .title h2 {
        font-size: 40px !important;
    }
    .tutorial_container {
        width: 100%;
    }
    .tutorial_holder iframe {
        width: 100% !important;
    }
    .event_left,
    .event_right {
        width: 100%;
    }
    .single_webinar_container {
        display: block;
    }
    .single_webinar_container a {
        display: table;
    }
    .event_right p {
        font-size: 14px;
        line-height: 18px;
    }
    .single .content-page-section img,
    .single .content-page-section figure {
        max-width: 100%;
        margin: 0;
    }
    .dark_overlay {
        position: relative !important;
        height: calc(60vh - 39px) !important;
    }
    .news_links a {
        display: block;
        font-size: 14px;
    }
    .single-positions main section.content-page-section div ul li {
        font-size: 14px !important;
        letter-spacing: 0;
        line-height: 30px;
    }
    section.breadcrumbs-section .container-full .breadcrumbs-page span {
        z-index: 3;
        position: relative;
    }
}


// event page template single event card 

.single_event_card {
    background: #f5fbff;
    display: flex;
    padding: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 10px;
    }
    &__featured {
        background: #E4ECF9;
    }
    &__default {
        background: #f5fbff;
    }
    a.title-link {
        color: #0c0d0e;
    }
    a.go-event {
        display: block;
        width:100px;
        text-align: right;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 30px;
        }
        img {
            width: 40px;
            @media screen and (max-width: 768px) {
                width: 50px;
            }
            &:hover {
                transform: scale(1.1);
                transition: 0.2s;
            }
        }
    }
    &__left_col {
        width: 350px;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 30px;
        }
        h4 {
            padding-bottom: 30px;
            @media screen and (max-width: 768px) {
                font-size: 32px;
                line-height: 38px;
            }
        }
        p {
            font-size: 16px;
            line-height: 26px;
            font-weight: 700;
        }
    }
    &__right_col {
        width: calc(100% - 450px);
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 30px;
        }
        h4 {
            padding-bottom: 30px;
            @media screen and (max-width: 768px) {
                font-size: 32px;
                line-height: 38px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
        &__btn{
            display: flex;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }
    &__centered {
        width: 100%;
        text-align: center;
    }
}

// single event styles below
.add_ornaments_event {
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0%,210%);
        content: url(../img/oval_left_icon_section.svg);
        @media (max-width: 960px) {
                display: none;
        }
    }
}

.wrap-hero-event {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 35px;
    background-color: #f8f8f8;
    @media screen and (max-width: 768px) {
        margin-top: -5px;
        margin-bottom: 15px;
    }
    .column-event {
        width: 50%;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        gap: 35px;
        justify-content: space-between;
        /* background-color: yellow; */
        background: url(../img/small_red_oval_top_right.svg) no-repeat;
        background-position: top right;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        &__background {
            background-size: cover; 
            background-repeat:no-repeat; 
            background-position: left center;
        }
    }
    .extrapad {
        padding: 60px;
        @media screen and (max-width: 768px) {
            padding: 40px;
        }
    }
    .column-event-item {
        font-size: 45px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 30px;
        align-items: center;
        & > i {
            color: #eb2638;
            font-size: 35px;
            padding-bottom: 0px;
        }
        & > i + h4 {
            padding-bottom: 0px !important;
        }
    }
}


    
.wrap-hero-event-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    /* background-color: #f8f8f8; */
    .column-event-1 {
        width: 30%;
        padding: 20px 40px 40px 20px;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 5px;
        }
        .event-details-left {
            background-color: #ffffff;
            border: 1px #eb2638 solid;
            border-radius: 20px;
            padding: 25px 30px;
            &__large_margintop {
                margin-top: 20px;
            }
            &__small_margintop {
                margin-top: 8px;
            }
            h5.event-detail {
                font-weight: 600;
                padding-bottom:0;
            }
            p.event-detail_p {
                font-size: 16px;
                line-height: 30px;
            }

        }
        /* background-color: yellow; */
    }
    .column-event-2 {
        width: 70%;
        /* background-color: yellow; */
        padding: 20px 10px 40px;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 10px 10px 40px;
        }
        & > p {
            font-size: 16px !important;
            line-height: 30px !important;
        }
        & > ul {
            margin-top: 14px;
        }
    }
}


