.product-section {
    margin-bottom: 81px;
    @media (max-width: 490px) {
        margin-bottom: 81px;
    }
    .container {
        .product-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-left: -1rem;
            margin-bottom: -1rem;
            padding-top: 60px;
            @media (max-width: 790px) {
                flex-direction: column;
                margin-left: 0;
                padding-top: 0px;
            }
            .woocommerce-product-gallery {
                display: flex;
                flex-direction: column;
                width: calc(45% - 1rem);
                margin-left: 1rem;
                margin-bottom: 1rem;
                justify-content: start;
                align-items: center;
                overflow: hidden;
                @media (max-width: 790px) {
                    width: 100%;
                    margin-left: 0;
                }
                .flex-control-nav {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    & > li:not(:last-child) {
                        margin-right: 20px;
                    }
                    li > img {
                        box-sizing: border-box;
                        height: 100px;
                        width: 100px;
                        border: 1px solid #f1f2f3;
                        background-color: #F1F2F3;
                        opacity: 0.88;
                        padding: 10px;
                        @media (max-width: 490px) {
                            height: 60px;
                            width: 60px;
                        }
                        &.flex-active {
                            border: 1px solid #404041;
                            background-color: transparent;
                            opacity: 1;
                        }
                    }
                }
                .flex-viewport {
                    min-height: 420px;
                    margin-bottom: 20px;
                    width:100%;
                    max-width: 100%;
                    @media (max-width: 490px) {
                        min-height: 400px;
                        max-height: 400px;
                    }
                    .woocommerce-product-gallery__wrapper {
                        margin: 0px;
                        .woocommerce-product-gallery__image {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            & > a {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                & > img {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    object-fit: cover;
                                    max-width: 100%;
                                    @media (max-width: 490px) {
                                        object-fit: contain;
                                    }
                                }
                            }
                        }
                    }
                }
                .woocommerce-product-gallery__image--placeholder {
                    & > img {
                        max-height: 300px;
                        object-fit: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .product-meta {
                display: flex;
                flex-direction: column;
                width: calc(47% - 1rem);
                margin-left: 1rem;
                margin-bottom: 1rem;
                @media (max-width: 790px) {
                    width: 100%;
                    margin-left: 0;
                }
                & > h1 {
                    padding-bottom: 10px;
                }
                &__subtitle {
                    h3.h3-style-product-subtitle > p {
                        font-size: 31.25px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 50px;
                        padding-bottom: 25px;
                        @media (max-width: 1600px) {
                            font-size: 31.25px;
                            line-height: 50px;
                        }
                        @media (max-width: 590px) {
                            font-size: 20px;
                            line-height: 30px;
                        }

                    }
                    // p {
                    //     padding-bottom: 25px;
                    //     font-size: 22px;
                    //     letter-spacing: 0;
                    //     line-height: 42px;
                    //     @media (max-width: 1024px) {
                    //         font-size: 18px;
                    //         letter-spacing: 0;
                    //         line-height: 32px;
                    //     }
                    // }
                }
                &__short-descr {
                    padding-bottom: 25px;
                }
                &__descr {
                    display: flex;
                    flex-direction: column;
                    padding-top: 30px;
                    padding-left: 40px;
                    padding-bottom: 20px;
                    padding-right: 25px;
                    background-color: #f7f8f9;
                    @media (max-width: 790px) {
                        padding: 15px;
                    }
                    @media (max-width: 490px) {
                        padding: 20px 5px;
                    }
                    & > h2 {
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 27px;
                        padding-bottom: 10px;
                    }
                    &__characteristics {
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 27px;
                        padding-bottom: 30px;
                        ul {
                            display: flex;
                            flex-direction: column;
                            list-style: none;
                            font-weight: 400;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 32px;
                            @media (max-width: 490px) {
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 26px;
                            }
                            & > li {
                                display: flex;
                                &::before {
                                    content: "";
                                    height: 8px;
                                    width: 8px;
                                    background-color: $black;
                                    border-radius: 50%;
                                    margin-right: 20px;
                                    flex: 0 0 8px;
                                    margin-top: 13px;
                                    @media (max-width: 490px) {
                                        margin-right: 10px;
                                        margin-top: 9px;
                                    }
                                }
                            }
                        }
                    }
                    &__btn {
                        display: flex;
                        align-items: center;
                        padding-bottom: 30px;
                        @media (max-width: 490px) {
                            justify-content: center;
                        }
                        & > a {
                            font-size: 19.52px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 22.18px;
                            padding-top: 19px;
                            padding-bottom: 19px;
                            @media (max-width: 490px) {
                                font-size: 16.52px;
                                font-weight: 500;
                                letter-spacing: 0;
                                line-height: 20.18px;
                                padding-top: 10px;
                                padding: 15px 20px;
                            }
                        }
                    }
                    &__link {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        color: $primary;
                        & > a {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 25px;
                            padding-right: 10px;
                            @media (max-width: 490px) {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.single-product.woocommerce.woocommerce-page {
    .availability-section {
        padding-bottom: 81px;
        margin-bottom: 81px;
        @media (max-width: 1024px) {
            margin-bottom: 81px;
        }
        @media (max-width: 890px) {
            margin-bottom: 81px;
        }
        @media (max-width: 490px) {
            margin-bottom: 40px;
        }
        .container {
            padding-top: 74px;
            padding-bottom: 0px;
        }
    }
    .accordion-section {
        margin-bottom: 81px;
        @media (max-width: 1024px) {
            margin-bottom: 81px;
        }
        @media (max-width: 890px) {
            margin-bottom: 81px;
        }
        @media (max-width: 490px) {
            margin-bottom: 40px;
        }
        .container {
            .accordion {
                margin-top: 0;
                padding-top: 20px;
            }
        }
    }
    .image-text-section {
        margin-bottom: 81px;
        @media (max-width: 1024px) {
            margin-bottom: 81px;
        }
        @media (max-width: 890px) {
            margin-bottom: 81px;
        }
        @media (max-width: 490px) {
            margin-bottom: 40px;
        }
    }
    .contact-form-section {
        width: 100%;
        .container {
            padding-bottom: 90px;
        }
        .wpcf7-acceptance label {
            position: relative;
            cursor: pointer;
            & > span {
                margin-top: 30px;
                @media (max-width: 590px) {
                    font-size: 12px;
                    line-height: 20px;
                    margin-top: 26px;
                }
            }
        }
        .wpcf7-acceptance input[type="checkbox"] {
            /*position: relative;*/
            position: absolute;
            visibility: hidden;
            width: 30px;
            height: 30px;
            top: 0;
            left: 0;
        }
        .wpcf7-acceptance input[type="checkbox"] + span:before {
            display: block;
            position: absolute;
            content: "";
            border-radius: 0;
            height: 25px;
            width: 25px;
            top: 50%;
            left: 0px;
            background-color: #fff;
            // border: 2px solid $primary;
            transform: translate(0, -50%);
            @media (max-width: 490px) {
                height: 20px;
                width: 20px;
                top: 57%;
            }
        }
        .wpcf7-acceptance input[type="checkbox"] + span:after {
            display: block;
            position: absolute;
            content: "\2713";
            height: 25px;
            width: 25px;
            top: 50%;
            left: 0;
            visibility: hidden;
            font-size: 18px;
            color: #fff;
            background-color: #EB2638;
            text-align: center;
            line-height: 25px;
            transform: translate(0, -50%);
            @media (max-width: 490px) {
                height: 20px;
                width: 20px;
                line-height: 19px;
            }
        }
        .wpcf7-acceptance input[type="checkbox"]:checked + span:before {
            background: transparent;
        }
        .wpcf7-acceptance input[type="checkbox"]:checked + span:after {
            visibility: visible;
        }
        
        @media (max-width: 490px) {
            .wpcf7-form-control.wpcf7-acceptance > span > label > span {
                padding-left: 30px;
            }
        }
        .wpcf7-spinner {
            display: none;
        }
        .wpcf7-form-control.wpcf7-acceptance {
            & > span {
                & > label {
                    & > span {
                        padding-left: 40px;
                        // margin-top: 30px;
                    }
                }
            }
        }
        
         
    }
}
