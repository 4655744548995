section.card-link-section {
    padding-bottom: 81px;
    // @media (max-width: 1600px) {
    //     padding-bottom: 81px;
    // }
    @media (max-width: 1024px) {
        padding-bottom: 81px;
    }
    @media (max-width: 890px) {
        padding-bottom: 40px;
    }
    .container {
        .card-list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 30px;
            .card-row {
                display: flex;
                flex-direction: row;
                gap: 30px;
                @media (max-width: 890px) {
                    flex-direction: column;
                }
            }
            &__item {
                border-radius: 5px;
                background-color: #f7f8f9;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 50px 45px;
                // margin-bottom: 36px;
                transition: all 0.3s;
                // margin-left: 2rem;
                // margin-bottom: 2rem;
                // width: calc(1 / 3 * 100% - 2rem);
                // min-width: 300px;
                width: 33%;
                min-width: 400px;
                max-width: 400px;
                & > h4 {
                    padding-bottom: 15px;
                    color: $woodsmoke;
                    font-family: Inter;
                    // font-size: 25.35px;
                    // font-weight: 600;
                    // letter-spacing: 0;
                    // line-height: 36px;
                    text-align: center;
                    // @media (max-width: 1600px) {
                    //     height: 80px;
                    // }
                    @media (max-width: 490px) {
                        height: initial;
                        font-size: 22px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 26px;
                    }
                }
                &__descr {
                    color: $woodsmoke;
                    font-family: Inter;
                    // font-size: 22px;
                    // letter-spacing: 0;
                    // line-height: 40px;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 26px;
                    text-align: center;
                    padding-bottom: 24px;
                    max-width: 310px;
                    // @media (max-width: 1600px) {
                    //     font-size: 16px;
                    //     letter-spacing: 0;
                    //     line-height: 26px;
                    // }
                }
                &__link {
                    & > a {
                        font-family: Inter;
                        font-size: 22px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 25px;
                        text-align: center;
                        color: $primary;
                        @media (max-width: 490px) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                        & > i {
                            color: $primary;
                            font-size: 22px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 25px;
                            text-align: center;
                        }
                        &:hover {
                            opacity: 0.8;
                            transition: all 0.3s;
                            & > i {
                                opacity: 0.8;
                                transition: all 0.3s;
                            }
                        }
                    }
                }
                &:hover {
                    box-shadow: 0 32px 48px -2px rgba(14, 31, 53, 0.2);
                    transition: all 0.3s;
                }
            }
        }
    }
}
