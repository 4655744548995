// section.news-section.ui-component {
//     padding-bottom: 20px;
// }
section.alerting-solution-section.ui-component {
    // margin-bottom: 0px;
    margin-top: 0px;
    height: inherit;
    padding-bottom: 20px;
    padding-top: 0px;
}
.description-section-icon-ui {
    color: $woodsmoke;
    font-family: Inter;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    padding-bottom: 81px;
}
section.banners-sections-ui {
    position: relative;
    padding-top: 20px;
    padding-bottom: 81px;
    margin-bottom: 30px;
    @media (max-width: 890px) {
        padding-bottom: 60px;
    }
    &::after {
        position: absolute;
        right: 0;
        top: 20%;
        content: url(../img/oval_right_red.svg);
        transform: translate(0, -50%);
    }
    .container {
        display: flex;
        flex-direction: column;
        & > h2 {
            color: $dark;
            font-family: Inter;
            // font-size: 46px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 65px;
            text-align: center;
            padding-bottom: 55px;
            @media (max-width: 890px) {
                // font-size: 18px;
                // line-height: 38px;
                padding-bottom: 40px;
            }
        }
        .section-descr {
            padding-bottom: 81px;
            color: $woodsmoke;
            font-family: Inter;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 42px;
            @media (max-width: 890px) {
                padding-bottom: 20px;
                & > p {
                    font-size: 14px;
                    line-height: 25px;
                }
            }
        }
        .banner-list {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: -2rem;
            margin-bottom: -2rem;
            @media (max-width: 890px) {
                margin-left: 0;
                flex-direction: column;
            }
            &__item {
                position: relative;
                display: flex;
                height: 252px;
                min-width: 290px;
                margin-left: 2rem;
                margin-bottom: 1rem;
                width: calc(1 / 4 * 100%);
                @media (min-width: 1500px) {
                    width: calc(1 / 4 * 100% - 2rem);
                }
                @media (max-width: 890px) {
                    margin-left: 0;
                    width: 100%;
                }
                & > img {
                    width: 100%;
                    object-fit: cover;
                    max-height: 100%;
                }
                & > a {
                    position: absolute;
                    top: 75%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    font-size: 20px;
                    min-width: 261px;
                    @media (max-width: 890px) {
                        font-size: 16px;
                    }
                }
                &::before {
                    position: absolute;
                    content: "";
                    opacity: 0.28;
                    background: linear-gradient(180.98deg, #02024e 0%, #024c87 100%);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
    }
}
section.title-section.flexible-template {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > p {
            max-width: 46%;
            align-self: flex-start;
            @media (max-width: 590px) {
                max-width: 100%;
                text-align: center;
                align-self: center;
            }
            &.main-p__hero {
                font-size: 25px;
                line-height: 35px;
                font-weight: 400;
                @media (max-width: 590px) {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }
            }
        }
        &.left-temp {
            word-break: keep-all; 
            & > h1 {
                padding-bottom: 15px;
                max-width: 47%;
                align-self: flex-start;
                @media (max-width: 590px) {
                    max-width: 100%;
                    padding-bottom: 10px;
                    align-self: center;
                    text-align: center;
                }
            }
            .sub-title-flexible-template {
                padding-bottom: 15px;
                max-width: 50%;
                align-self: flex-start;
                @media (max-width: 590px) {
                    max-width: 100%;
                    display: flex;
                    align-self: center;
                    padding-bottom: 0;
                }
                & > span {
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    align-self: flex-start;
                    @media (max-width: 1350px) {
                        font-size: 20px;
                    }
                    @media (max-width: 890px) {
                        font-size: 18px;
                        line-height: 30px;
                    }
                    @media (max-width: 590px) {
                        max-width: 100%;
                        padding-bottom: 5px;
                        align-self: center;
                        text-align: center;
                        line-height: 25px;
                        display: none;
                    }
                }
            }
            &.left-temp__centre {
                & > h1 {
                    max-width: 47%;
                    align-self: flex-start;
                    word-break: keep-all;
                    @media (max-width: 590px) {
                        max-width: 100%;
                        align-items: center;
                        text-align: center;
                    }
                }
            }
        }
        .sub-title-flexible-template {
            & > span {
                color: $white;
                font-family: Inter;
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 35px;
                padding-bottom: 30px;
                width: 100%;
                @media (max-width: 890px) {
                    font-size: 18px;
                    line-height: 50px;
                    align-items: center;
                    text-align: left;
                }
            }
        }
    }
}
section.icon-descr-row-section.ui {
    position: relative;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0, -30%);
        content: url(../img/oval_left_icon_section.svg);
    }
    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: url(../img/oval_right_icon_section.svg);
    }
}
.alerting-solution-section.ui {
    padding-top: 0;
    margin-top: 0;
    // padding-bottom: 0;
    // margin-bottom: 0;
}
.solution-section.ui-ajax {
    position: relative;
    z-index: 0;
    &::after {
        position: absolute;
        content: url(../img/red_right.svg);
        right: 0;
        bottom: 5%;
        z-index: -1;
    }
}
.solution-section.ui {
    .container {
        .solution-section-content {
            margin-left: -1rem;
            &__tabs {
                margin-left: 1rem;
                margin-bottom: 1rem;
                width: calc(1 / 2.0447 * 100% - 1rem);
            }
            &__static {
                margin-left: 1rem;
                margin-bottom: 1rem;
                width: calc(1 / 2.0447 * 100% - 1rem);
            }
        }
    }
}
.alerting-solution-section.header_with_content_in_two_columns {
    background-color: rgba(147, 148, 148, 5%);
    margin-bottom: 81px;
    margin-top: 0;
    .container {
        & > h2,
        h3,
        h4,
        h5,
        p,
        span {
            max-width: 100%;
        }
        .alerting-solution__content {
            & > p {
                padding-bottom: 50px;
                @media (max-width: 890px) {
                    padding-bottom: 30px;
                    font-size: 14px;
                    line-height: 28px;
                }
            }
            & > .btn {
                display: flex;
                align-items: center;
                @media (max-width: 890px) {
                    justify-content: center;
                }
                & > a {
                    color: $white;
                    padding: 22px 45px;
                    @media (max-width: 590px) {
                        font-size: 16px;
                        padding: 11px 25px;
                    }
                    &:hover {
                        color: $primary;
                    }
                    &:active {
                        color: $white;
                    }
                }
            }
        }
    }
}
.title-section.flexible-template.left-temp {
    height: calc(100vw - 100vh);
    min-height: 500px;
    max-height: 500px;
    @media (max-width: 1600px) {
        min-height: 500px;
        max-height: 500px;
    }
    @media (max-width: 1350px) {
        min-height: 500px;
    }
    @media (max-width: 1090px) {
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto;
    }
    @media (max-width: 890px) {
        min-height: 350px;
        height: auto;
        background-position: bottom;
        align-items: center;
        padding-top: 70px;
    }
    @media (max-width: 590px) {
        min-height: 199px;
        height: 220px;
        max-height: 250px;
        background-position: bottom;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
        &.expand-height {
            height: 280px;
            max-height: 310px;
        }
    }
    .main-h1 {
        @media (max-width: 590px) {
            color: #ffffff !important;
        }
    }
    .main-p {
        @media (max-width: 590px) {
            color: #ffffff !important;
        }
    }
}

.title-section.flexible-template.left-temp.no-subtitle-side-circle {
    height: calc(100vw - 100vh);
    max-height: 500px;
    min-height: 500px;
    @media (max-width: 1350px) {
        max-height: 500px;
        min-height: 500px;
    }
    @media (max-width: 1090px) {
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto;
    }
    @media (max-width: 890px) {
        min-height: 350px;
        height: auto;
        background-position: bottom;
        align-items: center;
        padding-top: 70px;
    }
    @media (max-width: 590px) {
        min-height: 199px;
        height: 220px;
        max-height: 250px;
        background-position: bottom;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
        &.expand-height {
            height: 280px;
            max-height: 310px;
        }
    }
    // & > h1 {
    //     @media (max-width: 890px) {
    //         font-size: 36px;
    //         line-height: 50px;
    //     }
    // }
    .main-h1 {
        @media (max-width: 590px) {
            color: #ffffff !important;
        }
    }
    .main-p {
        @media (max-width: 590px) {
            color: #ffffff !important;
        }
    }
}
.title-section.flexible-template.left-temp.no-subtitle {
    height: calc(100vw - 100vh);
    max-height: 500px;
    min-height: 500px;
    @media (max-width: 1350px) {
        max-height: 500px;
        min-height: 500px;
    }
    @media (max-width: 1090px) {
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto;
    }
    @media (max-width: 890px) {
        min-height: 350px;
        height: auto;
        background-position: bottom;
        align-items: center;
        padding-top: 70px;
    }
    @media (max-width: 590px) {
        min-height: 199px;
        height: 220px;
        max-height: 250px;
        background-position: center center;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
        &.expand-height {
            height: 280px;
            max-height: 310px;
        }
    }
    & > h1 {
        // @media (max-width: 890px) {
        //     font-size: 36px;
        //     line-height: 50px;
        // }
        @media (max-width: 590px) {
            padding-bottom: 0;
        }
    }
    .main-h1 {
        @media (max-width: 590px) {
            color: #ffffff !important;
            padding-bottom: 0;
        }
    }
    .main-p {
        @media (max-width: 590px) {
            color: #ffffff !important;
        }
    }
}
.title-section.flexible-template.temp-single-news-header {
    height: calc(100vw - 100vh);
    max-height: 500px;
    min-height: 500px;
    background: url("https://www.swissphone.com/wp-content/uploads/2022/06/Heros_Desktop_diverse_neu2.png");
    background-position: center center;
    background-size: cover;
    @media (max-width: 1350px) {
        max-height: 500px;
        min-height: 500px;
    }
    @media (max-width: 1090px) {
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto;
    }
    @media (max-width: 890px) {
        min-height: 350px;
        height: auto;
        background-position: bottom;
        align-items: center;
        padding-top: 70px;
    }
    @media (max-width: 590px) {
        min-height: 199px;
        height: 220px;
        max-height: 250px;
        background-position: bottom;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
        background: linear-gradient( rgba(0, 0, 0, 0), rgba(2, 76, 135, 0.5) ), url("https://www.swissphone.com/wp-content/uploads/2022/06/Heros_Desktop_diverse_neu2.png");
        background-position: center center;
        background-size: cover;
    }
    & > h1 {
        @media (max-width: 890px) {
            font-size: 32px;
            line-height: 42px;
        }
    }
    .main-h1 {
        @media (max-width: 590px) {
            color: #ffffff !important;
        }
    }
    .main-p {
        @media (max-width: 590px) {
            color: #ffffff !important;
        }
    }
}
.page-template-flexible-page-php {
    .breadcrumbs-section {
        padding-bottom: 70px;
        @media (max-width: 890px) {
            padding-bottom: 30px;
        }
    }
}
section.flexible-title-section {
    padding-bottom: 40px;
    @media (max-width: 961px) {
        padding-bottom: 40px;
    }
}
// section.space-between {
//     display: none;
// }
section.solution-section.ui-ajax {
    & > .container {
        & > h3 {
            @media (max-width: 890px) {
                font-size: 22px;
                line-height: 32px;
                margin-bottom: 30px;
            }
        }
    }
}
@media (max-width: 890px) {
    .webinars_container {
        & > .single_webinar_container {
            padding: 20px 15px;
            margin-bottom: 15px;
            & > a {
                & > img {
                    width: 20px;
                }
            }
        }
    }
}
.list-with-icons-section {
    .container {
        .list-with-icons-blocks {
            .list-with-icons-block {
                align-items: flex-start;
                & > p {
                    font-size: 18px;
                    line-height: 26px;
                    &::before {
                        min-width: 20px;
                        min-height: 20px;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
}
.info-blocks-section {
    padding-bottom: 81px;
    @media (max-width: 1024px) {
        padding-bottom: 81px;
    }
    @media (max-width: 890px) {
        padding-bottom: 60px;
    }
    @media (max-width: 490px) {
        padding-bottom: 40px;
    }
    .container {
        .info-blocks {
            flex-direction: column;
            gap: initial;
            &__item {
                &:not(:last-child) {
                    margin-bottom: 40px;
                    @media (max-width: 890px) {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
}
.downloads-section {
    padding-bottom: 81px;
    @media (max-width: 1024px) {
        padding-bottom: 81px;
    }
    @media (max-width: 890px) {
        padding-bottom: 60px;
    }
    @media (max-width: 490px) {
        padding-bottom: 40px;
    }
}
.footer-section .language-navi .wpml-ls-legacy-dropdown .wpml-ls-current-language img.wpml-ls-flag {
    margin-right: 10px;
}
