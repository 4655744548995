section.product-list-section {
    margin-bottom: 81px;
    .container {
        .product-list {
            display: flex;
            flex-wrap: wrap;
            // margin-left: -3rem;
            gap: 1.5rem;
            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: stretch;
                // min-width: 300px;
                // padding: 15px;
                // margin-left: 2rem;
                // margin-bottom: 2rem;
                width: calc(1 / 3 * 100% - 1rem);
                background-color: #fbf9fa;
                &__img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    margin-top: 15px;
                    // width: 220px;
                    // height: 220px;
                    & > img {
                        width: 100%;
                        object-fit: contain;
                        width: 350px;
                        height: 260px;
                    }
                }
                &__title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: $primary;
                    padding: 0 30px 10px 30px;
                    & > a {
                        & > h4 {
                            font-size: 22px;
                            text-align: center;
                        }
                    }
                }
                &__descr,
                &__descr p {
                    color: $woodsmoke;
                    font-family: Inter;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 26px;
                    text-align: center;
                    // @media (max-width: 1600px) {
                    //     line-height: 24px;
                    // }
                }
                &__descr {
                    max-width: 320px;
                    padding-bottom: 50px;
                }
            }
        }
    }
}
@media screen and (max-width: 790px) {
	section.product-list-section {
		.container {
			.product-list {
				justify-content: center;
				margin-left: 0;
			}
			.product-list__item {
				width: calc(100% - 2rem);
				margin-left: 0;
			}
		}
	}
}