.quote-section {
    background-color: #f7f8f9;
    margin-bottom: 81px;
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        .quote-wrapper {
            max-width: 715px;
            display: flex;
            flex-direction: column;
            padding: 50px;
            background-color: $white;
            .quote-img {
                padding-bottom: 27px;
                & > img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 100%;
                    object-fit: contain;
                }
            }
            .quote-descr {
                padding-bottom: 10px;
                max-width: 715px;
                line-height: 1.5rem;
            }
            .quote-caption {
                & > span {
                    font-size: 15px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 24px;
                }
                &__black {
                    color: $black;
                }
                &__red {
                    color: $primary;
                }
            }
        }
    }
}
