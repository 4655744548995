@include font-face("Inter", "Inter-Black", 900);
@include font-face("Inter", "Inter-ExtraBold", 800, bold);
@include font-face("Inter", "Inter-Bold", 700);
@include font-face("Inter", "Inter-SemiBold", 600);
@include font-face("Inter", "Inter-Medium", 500);
@include font-face("Inter", "Inter-Regular", 400);
@include font-face("Inter", "Inter-Light", 300);
@include font-face("Inter", "Inter-ExtraLight", 200);
@include font-face("Inter", "Inter-Thin", 100);
@include font-face("Open sans", "open-sans-semibold", 600);
@include font-face("open-sans-semibold", "open-sans-semibold", 400);
@include font-face("Roboto-Black", "Roboto-Black", 400);
@include font-face("Roboto-Bold", "Roboto-Bold", 400);
@include font-face("Roboto-BoldItalic", "Roboto-BoldItalic", 400);
@include font-face("Roboto-Italic", "Roboto-Italic", 400);
@include font-face("Roboto-Light", "Roboto-Light", 400);
@include font-face("Roboto-BlackItalic", "Roboto-BlackItalic", 400);
@include font-face("Roboto-LightItalic", "Roboto-LightItalic", 400);
@include font-face("Roboto-Medium", "Roboto-Medium", 400);
@include font-face("Roboto-MediumItalic", "Roboto-MediumItalic", 400);
@include font-face("Roboto-Regular", "Roboto-Regular", 400);
@include font-face("Roboto-Thin", "Roboto-Thin", 400);
@include font-face("Roboto-ThinItalic", "Roboto-ThinItalic", 400);
