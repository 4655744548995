section.icon-link-list-section {
    margin-bottom: 81px;
    @media (max-width: 490px) {
        margin-bottom: 81px;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > h3 {
            font-size: 35px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 46px;
            text-align: center;
            max-width: 750px;
            padding-bottom: 81px;
            @media (max-width: 490px) {
                font-size: 26px;
                line-height: 36px;
                padding-bottom: 40px;
            }
        }
        .icon-link-list {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 20px;
            @media (max-width: 1250px) {
                grid-template-columns: 1fr auto;
            }
            @media (max-width: 767px) {
                grid-template-columns: auto;
            }
            .gray-btn {
                display: flex;
                justify-content: left;
                padding: 22px 45px 22px 40px;
                border-radius: 50px;
                background-color: #f9f9f9;
                width: fit-content;
                & > img {
                    display: inline-flex;
                    align-items: left;
                    justify-content: left;
                    height: 24px;
                    width: 32px;
                    margin-right: 7px;
                    margin-top: 1px;
                }
                & > span {
                    text-align: left;
                    font-size: 22px;
                    font-weight: 700;
                    letter-spacing: 0;
                    line-height: 30px;
                    color: $black;
                    @media (max-width: 970px) {
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 30px;
                    }
                }
                &:hover {
                    & > span {
                        color: $primary;
                    }
                    & > img {
                        filter: invert(27%) sepia(83%) saturate(4759%) hue-rotate(342deg) brightness(94%) contrast(95%);
                    }
                }
            }
        }
    }
}
