.text-by-column-section {
    padding-bottom: 81px;
    @media (max-width: 1024px) {
        padding-bottom: 81px;
    }
    @media (max-width: 650px) {
        padding-bottom: 40px;
    }
    .container {
        .column-list {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            margin-left: -1rem;
            margin-bottom: -2rem;
            &__item {
                display: flex;
                flex-direction: column;
                margin-left: 1rem;
                margin-bottom: 2rem;
                // width: calc(1 / 4 * 100% - 1rem);
                flex: 1;
                @media (max-width: 1190px) {
                    width: calc(1 / 2 * 100% - 1rem);
                }
                @media (max-width: 650px) {
                    width: calc(100% - 1rem);
                }
            }
        }
    }
}
