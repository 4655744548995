section.image-text-section {
    margin-bottom: 81px;
    @media (max-width: 1024px) {
        margin-bottom: 30px;
    }
    @media (max-width: 490px) {
        margin-bottom: 20px;
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // margin-bottom: -2rem;
        gap: 4rem;
        @media (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
        }
        .picture-col {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 270px;
            // width: calc(100% / 2 - 2rem);
            // width: 50%;
            flex: 1;
            max-height: 585px;
            overflow: hidden;
            // margin-left: 2rem;
            // margin-bottom: 2rem;
            @media (max-width: 1024px) {
                // width: calc(100% - 2rem);
                width:100%;

                margin-left: 0;
            }
            & > img {
                width: 100%;
                object-fit: cover;
                max-height: fill;
                align-self: flex-start;
                @media (max-width: 1024px) {
                    max-width: 600px;
                }
            }
        }
        .content-col {
            // width: calc(100% / 2 - 40px);
            // width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex: 1;
            // margin-left: 2rem;
            // margin-bottom: 2rem;
            // padding-left: 3rem;
            
            @media (max-width: 1024px) {
                // width: calc(100% - 2rem);
                width: 100%;

                align-items: center;
                margin-left: 0;
            }
            & > h3 {
                padding-bottom: 20px;
                @media (max-width: 1024px) {
                    text-align: center;
                }
            }
            .descr {
                padding-bottom: 30px;
                font-family: Inter;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 30px;
                color: $woodsmoke;
                @media (max-width: 1024px) {
                    padding-bottom: 40px;
                }
                p,
                div,
                span {
                    font-family: Inter;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                    @media (max-width: 1024px) {
                        font-family: Inter;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 22px;
                    }
                }
                p:not(:last-child) {
                    padding-bottom: 20px;
                }
                a {
                    color: $primary;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 32px;
                    & > li {
                        position: relative;
                        padding-left: 20px;
                        @media (max-width: 1024px) {
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 32px;
                        }
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 12px;
                            content: "";
                            height: 8px;
                            width: 8px;
                            background-color: $black;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .app-cta-logo-col {
                display: flex;
                justify-content: center;
                padding-top: 20px;
                @media (max-width: 1024px) {
                    flex-wrap: wrap;
                    & > a {
                        width: fit-content;
                    }
                }
                .btn-smaller {
                    margin: 8px 0;
                    // font-size: 18px;
                    // padding: 15px 32px 16px;
                }
                .btn-smaller-er {
                    margin: 5px 0;
                    // font-size: 16px;
                    // padding: 10px 25px 11px;
                }
                .btn-margin-right{
                    margin-right: 1rem;
                    @media (max-width: 590px) {
                        margin-right: 0;
                    }
                }
                .btn-margin-left {
                    margin-left: 1rem;
                    @media (max-width: 590px) {
                        margin-left: 0;
                    }
                }
            }
            // a.primary-btn {
            //     @media (max-width: 1600px) {
            //         font-size: 20px;
            //         line-height: 25px;
            //         text-align: center;
            //         padding: 15px 25px;
            //     }
            //     @media (max-width: 1024px) {
            //         font-size: 18px;
            //         line-height: 25px;
            //         text-align: center;
            //         padding: 15px 15px;
            //     }
            // }
        }
    }
}
