.text-two-column-section {
    padding-bottom: 81px;
    .container {
        padding: 20px 0;
        .col-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            margin-bottom: -1rem;
            column-gap: 30px;
            justify-content: space-between;
            // margin-left: -1rem;
            @media (max-width: 590px) {
                flex-direction: column;
            }
            &__item {
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                // margin-left: 1rem;
                width: calc(1 / 2 * 100% - 35px);
                @media (max-width: 590px) {
                    width: 100%;
                }
                &__row {
                    // padding: 10px;
                    margin-bottom: 2rem;
                    // & > h4 {
                    //     font-size: 30px;
                    //     font-weight: bold;
                    //     letter-spacing: 0;
                    //     line-height: 40px;
                    //     padding-bottom: 30px;
                    // }
                    &__descr {
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}
