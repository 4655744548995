section.testimonials-section {
    background-color: #f2f2f2;
    padding-bottom: 81px;
    padding-top: 85px;
    margin-bottom: 81px;
    .container {
        position: relative;
        & > h2 {
            color: $woodsmoke;
            font-family: Inter;
            // font-size: 46px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 65px;
            text-align: center;
            padding-bottom: 81px;
            max-width: 788px;
            margin: 0 auto;
            // @media (max-width: 490px) {
            //     font-size: 28px;
            //     line-height: 38px;
            // }
        }
        .testimonials {
            &__list {
                &__item {
                    display: flex;
                    flex-direction: column;
                    background-color: $white;
                    gap: 20px;
                    padding: 20px;
                    .rating {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        &__quo {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            & > img {
                                width: 100%;
                                object-fit: contain;
                            }
                        }
                        &__stars {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 5px;
                            cursor: pointer;
                            & > i.fa-star-o {
                                color: #e4e9f2;
                                transition: all 0.3s;
                                &:hover {
                                    color: $gold;
                                    transition: all 0.3s;
                                }
                                &.active {
                                    color: $gold;
                                    transition: all 0.3s;
                                }
                            }
                            & > i.fa-star {
                                color: $gold;
                                transition: all 0.3s;
                                &:hover {
                                    color: $gold;
                                    transition: all 0.3s;
                                }
                                &.active {
                                    color: $gold;
                                    transition: all 0.3s;
                                }
                            }
                        }
                    }
                    .testimonial-descr {
                        & > p,
                        span,
                        div {
                            color: #1d1d1e;
                            // font-family: Inter;
                            // font-size: 15px;
                            // font-weight: 400;
                            // letter-spacing: 0;
                            // line-height: 24px;
                            // text-align: justify;
                        }
                    }
                    .testimonial-author {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        width: 100%;
                        &__avatar {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            & > img {
                                width: 48px;
                                height: 48px;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                        }
                        &__name {
                            max-width: 75%;
                            & > span {
                                color: #1d1d1e;
                                font-family: Inter;
                                font-size: 15px;
                                font-weight: bold;
                                letter-spacing: 0;
                                line-height: 24px;
                                text-align: justify;
                            }
                        }
                    }
                }
            }
        }
    }
}
