.dropdown-mega-menu {
  display: none;
  opacity: 1;
  visibility: hidden;
  transition: none;
  z-index: -999999;
  ul {
    list-style: none;
  }
  a.nav-link[data-open-dropdown-level] {
    color: $primary;
    text-decoration: underline;
    display: block;
    cursor: pointer;
  }
  .nav-link-back {
    padding-top: 20px;
    cursor: pointer;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    & > span {
      font-family: "Open Sans";
      font-size: 15px;
      color: $woodsmoke;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 20px;
    }
  }
  .dropdown-mega-menu-wrapper {
    transition: none;
  }
  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    // left: 50%;
    bottom: 4px;
    width: 90%;
    background: rgba(6, 16, 88, 0.880873);
    filter: blur(135.914px);
    border-radius: 165px;
    height: 19px;
  }
  &.visible {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: 999999;
  }
  .dropdown-level-wrapper {
    display: flex;
    padding: 5% 20px;
  }
  .nav-item {
    margin-top: 28px;
    & > a {
      opacity: 0.76;
      color: #0c0d0e;
      font-family: "Open Sans";
      font-size: 15px;
      letter-spacing: 0.3px;
      line-height: 20px;
      display: block;
      min-width: 195px;
    }
    &:hover {
      & > a {
        color: $primary;
      }
    }
  }
  .nav-link {
    color: $woodsmoke;
    text-transform: none;
    text-decoration: none;
    font-weight: 400;
    display: block;
  }
  .dropdown-mega-menu-wrapper {
    background-color: $white;
    box-sizing: border-box;
    border: 1px solid rgba(205, 208, 227, 0.52);
    border-radius: 25px;
    padding: 10px 10px;
    border-radius: 25px;
    .dropdown-level.dropdown-sub-level {
      border-right: 0;
      .dropdown-level-wrapper {
        padding: 10px 20px 10px 20px;
        .dropdown-section {
          padding: 10px 10px 100px 10px;
        }
      }
    }
  }
  .dropdown-section {
    box-sizing: border-box;
    // border-right: 1px solid $lightGray;
    padding: 0 52px 0 32px;
    &:last-child {
      border-right: 0;
    }
  }
  .dropdown-level {
    &.visible {
      opacity: 1;
      display: block;
    }
    &.hidden {
      opacity: 1;
      display: none;
    }
  }
  .headline {
    display: flex;
    align-items: center;
    justify-content: center;
    & > a {
      flex: 1;
      color: $woodsmoke;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.3px;
      line-height: 20px;
      width: 100%;
      @media (min-width: 1300px) {
        min-width: 165px;
      }
    }
    & > img {
      margin-right: 16px;
    }
  }
  .sub-level-header {
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__title {
      color: $primary;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 0.3px;
      line-height: 20px;
      padding: 0 52px 0 32px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: -25px;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  &.visible > #arrow-popper {
    transform: translateY(0);
  }
}

#arrow-popper {
  position: absolute;
  top: -12px;
  z-index: 2;
  transform: translateY(10px);
  transition: none;
}
