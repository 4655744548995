section.news-section {
    padding-top: 20px;
    padding-bottom: 81px;
    @media (max-width: 490px) {
        padding-bottom: 60px;
    }
    .container {
        display: flex;
        flex-direction: column;
        & > h3 {
            color: $dark;
            font-family: Inter;
            // font-size: 35px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 46px;
            text-align: center;
            padding-bottom: 65px;
            @media (max-width: 490px) {
                // font-size: 26px;
                // line-height: 38px;
                padding-bottom: 40px;
            }
        }
        .description-news-section {
            padding-bottom: 65px;
            @media (max-width: 490px) {
                padding-bottom: 40px;
            }
            div,
            p,
            span,
            a {
                color: $woodsmoke;
                font-family: Inter;
                // font-size: 22px;
                // letter-spacing: 0;
                // line-height: 42px;
                // @media (max-width: 490px) {
                //     font-size: 16px;
                //     line-height: 28px;
                // }
            }
            a {
                color: $primary;
            }
        }
        .all-news-link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            padding-bottom: 24px;
            transition: all 0.3s;
            & > a {
                color: #062336;
                font-family: Inter;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 25px;
                @media (max-width: 490px) {
                    font-size: 18px;
                    letter-spacing: 0;
                    line-height: 20px;
                }
                & > i {
                    color: #062336;
                }
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
.news-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-bottom: -2rem;
    @media (max-width: 490px) {
        margin-left: 0;
    }
    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-content: flex-start;
        background-color: #f7f8f9;
        // max-width: 420px;
        min-width: 300px;
        width: calc(100% / 3 - 2rem);
        margin-left: 2rem;
        margin-bottom: 2rem;
        @media (max-width: 1024px) {
            width: calc(100% / 2 - 2rem);
        }
        @media (max-width: 490px) {
            min-width: 290px !important;
            width: 100%;
            margin-left: 0rem;
        }
        &__img {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            // &::after {
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     opacity: 0.28;
            //     background: linear-gradient(180.98deg, #02024e 0%, #eb2638 100%);
            // }
            & > img {
                width: 100%;
                object-fit: cover;
                height: 205px;
            }
        }
        &__contents {
            display: flex;
            flex-direction: column;
            padding: 20px 43px 60px 43px;
            @media (max-width: 490px) {
                padding: 15px 15px 30px;
            }
            &__title {
                padding-bottom: 10px;
                & > h4 {
                    color: #404041;
                    font-family: Inter;
                    // font-size: 24px;
                    // font-weight: 400;
                    // letter-spacing: 0;
                    // line-height: 35px;
                    text-align: left;
                    @media (max-width: 490px) {
                        // font-size: 18px;
                        // font-weight: 600;
                        // letter-spacing: 0;
                        // line-height: 28px;
                        text-align: center;
                    }
                }
            }
            &__descr {
                padding-bottom: 36px;
                opacity: 0.6;
                @media (max-width: 490px) {
                    padding-bottom: 20px;
                }
                & > span {
                    color: #404041;
                    font-family: Inter;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                    text-align: left;
                    @media (max-width: 490px) {
                        line-height: 28px;
                    }
                }
            }
            &__link {
                text-align: left;
                opacity: 0.9;
                & > span {
                    color: $primary;
                    font-family: Inter;
                    font-size: 22px;
                    letter-spacing: 0;
                    line-height: 25px;
                    transition: all 0.3s;
                    @media (max-width: 490px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                &:hover {
                    opacity: 0.6;
                }
                & > div {
                    color: $primary;
                    font-family: Inter;
                    font-size: 22px;
                    letter-spacing: 0;
                    line-height: 25px;
                    transition: all 0.3s;
                    @media (max-width: 490px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}
