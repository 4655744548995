.mobail-search-input {
    display: none;
    z-index: 9;
    @media (max-width: 1230px) {
        display: flex;
        width: 50%;
        position: relative;
        align-items: center;
    }
    @media (max-width: 700px) {
        width: 47%;
    }
    @media (max-width: 650px) {
        width: 100%;
    }
    @media (max-width: 590px) {
        position: initial;
    }
}
.burger-menu {
    display: none;
    background-color: $black;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    flex: 0 0 44px;
    & > i {
        color: $white;
    }
    @media (max-width: 1230px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
div.navbar-header-mobail {
    display: none;
    @media (max-width: 1230px) {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        position: fixed;
        z-index: 100;
        width: 100%;
        height: 100%;
        left: -100%;
        top: 0;
        background: $white;
        transition: left 0.4s ease;
        overflow-y: auto;
    }
    .mobail-menu_head {
        display: none;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 50px;
        @media (max-width: 1230px) {
            display: flex;
            justify-content: space-between;
        }
    }
    .navbar-navi {
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 20px;
        margin-bottom: 25px;
        max-width: 400px;
        margin: 0 auto;
        min-width: 400px;
        @media (max-width: 490px) {
            max-width: 100%;
            margin: 0;
            min-width: 270px;
        }
        .mobile-navi {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
            & > .nav-mobail-item {
                position: relative;
                padding-bottom: 10px;
                &:not(:last-child) {
                    margin-bottom: 15px;
                    border-bottom: 1px solid #ececec;
                }
                & > span {
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    position: absolute;
                    top: 4px;
                    right: 0px;
                    height: 100%;
                    width: 40%;
                    & > img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 18.85px;
                        height: 18.85px;
                        object-fit: contain;
                    }
                }
                & > .nav-link {
                    text-transform: uppercase;
                    color: #0c0d0e;
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0.37px;
                    line-height: 28px;
                }
                .dropdown-mobail-menu {
                    display: none;
                }
                .first-lavel-mobail-menu {
                    display: none;
                }
                &.simple-menu {
                    & > .simple-dropdown-menu {
                        display: none;
                        & > .nav-mobail-item.simple-menu {
                            & > span.right-arrow {
                                display: none;
                            }
                        }
                    }
                    &.visible {
                        & > .simple-dropdown-menu {
                            display: flex;
                            flex-direction: column;
                            margin-left: 30px;
                            margin-bottom: 20px;
                            margin-top: 20px;
                            & > .nav-mobail-item.simple-menu {
                                & > a {
                                    opacity: 0.59;
                                    color: #0c0d0e;
                                    font-family: Inter;
                                    font-size: 16px;
                                    font-weight: bold;
                                    letter-spacing: 0.52px;
                                    line-height: 28px;
                                    text-align: center;
                                }
                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                        & > span {
                            & > img {
                                transform: rotate(90deg);
                            }
                        }
                    }
                    &.hidden {
                        display: none;
                        &:not(:last-child) {
                            border-bottom: 0;
                        }
                    }
                }
                &.dropdown-mobail-menu-item {
                    & > .first-lavel-mobail-menu {
                        display: none;
                        .nav {
                            .nav-plus-lavel {
                                display: none;
                            }
                        }
                    }
                    &.visible {
                        border-bottom: 0;
                        display: flex;
                        flex-direction: column-reverse;
                        & > span.right-arrow {
                            // left: 0;
                            // justify-content: flex-start;
                            // top: 5px;
                            width: 17%;
                            height: 10%;
                            & > img {
                                transform: rotate(90deg);
                                filter: brightness(0);
                            }
                        }
                        & > .first-lavel-mobail-menu {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 20px;
                            margin-top: 20px;
                            & > .nav {
                                position: relative;
                                &:not(:last-child) {
                                    margin-bottom: 15px;
                                    padding-bottom: 15px;
                                    border-bottom: 1px solid #ececec;
                                }
                                & > span {
                                    display: flex;
                                    justify-content: flex-end;
                                    position: absolute;
                                    top: 4px;
                                    right: 0px;
                                    height: 100%;
                                    width: 40%;
                                    cursor: pointer;
                                    & > img {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 18.85px;
                                        height: 18.85px;
                                        object-fit: contain;
                                    }
                                }
                                & > span.nav-link-back {
                                    height: 40px;
                                    width: 52px;
                                }
                                & > a {
                                    opacity: 0.59;
                                    color: #0c0d0e;
                                    font-family: Inter;
                                    font-size: 16px;
                                    font-weight: bold;
                                    letter-spacing: 0.52px;
                                    line-height: 28px;
                                    text-align: center;
                                }
                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                }
                                .nav-link-back {
                                    display: none;
                                }
                                &.show {
                                    border-bottom: 0;
                                    margin-left: 80px;
                                    padding-top: 60px;
                                    .nav-link-back {
                                        display: flex;
                                        left: 0;
                                        top: 0;
                                        color: $primary;
                                    }
                                    & > span.right-arrow {
                                        left: -66px;
                                        justify-content: flex-start;
                                        top: 0;
                                        & > img {
                                            transform: rotate(180deg);
                                        }
                                    }
                                    & > .nav-plus-lavel {
                                        display: flex;
                                        flex-direction: column;
                                        padding-top: 50px;
                                        .nav-item-lavel {
                                            padding-bottom: 20px;
                                            display: flex;
                                            flex-direction: column;
                                            & > a {
                                                opacity: 0.76;
                                                color: #0c0d0e;
                                                font-family: Inter;
                                                font-size: 16px;
                                                letter-spacing: 0.52px;
                                                line-height: 26px;
                                            }
                                            a[data-open-dropdown-level="plusLavel"] {
                                                color: $primary;
                                                text-decoration: underline;
                                                padding-bottom: 50px;
                                                order: 1;
                                            }
                                        }
                                        .plusLavel-list {
                                            display: none;
                                            &.active {
                                                order: 0;
                                                display: flex;
                                                flex-direction: column;
                                                .nav-item-plusLavel {
                                                    padding-bottom: 20px;
                                                    display: flex;
                                                    flex-direction: column-reverse;
                                                    & > a {
                                                        opacity: 0.76;
                                                        color: #0c0d0e;
                                                        font-family: Inter;
                                                        font-size: 16px;
                                                        letter-spacing: 0.52px;
                                                        line-height: 26px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &.hidden {
                                    display: none;
                                }
                            }
                        }
                        & > span.right-arrow.hidden {
                            display: none;
                        }
                        & > .nav-link.dropdown-mobail-menu-link.hidden {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .widget-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .support-dropdown {
            max-width: 400px;
            width: 80%;
            margin-bottom: 10px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            &__btn {
                &__list {
                    .btn-content {
                        width: 100%;
                        padding-left: 0;
                    }
                    &__items {
                        padding-top: 30px;
                        padding-bottom: 30px;
                        &.active {
                            border-bottom: none;
                            border-left: none;
                            border-right: none;
                            visibility: initial;
                            position: initial;
                            z-index: 9;
                        }
                    }
                }
            }
        }
        & > a.primary-btn {
            width: 80%;
            max-width: 400px;
            padding: 17px 20px;
            margin-bottom: 10px;
        }
        .user-auth {
            width: 80%;
            margin-bottom: 10px;
            max-width: 400px;
            margin-bottom: 10px;
            & > a {
                border: 1px solid #eb2638;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 12px 6%;
                span {
                    color: $primary;
                }
                & > img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
    &.show {
        left: 0px;
        transition: left 0.4s ease;
        z-index: 999999;
    }
}
