section.title-section {
    @include bg(cover, center);
    background-color: #d8d8d8;
    height: calc(0.54 * 100vh);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1090px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    @media (max-width: 490px) {
        min-height: calc(60vh - 63px);
        height: auto;
        background-position: bottom;
        align-items: flex-start;
        padding-top: 70px;
    }
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        & > h1 {
            text-transform: uppercase;
            text-align: left;
            color: $white;
            @media (max-width: 590px) {
                text-align: left;
            }
        }
    }
    &.no-breadcrumbs.flexible-template {
        padding: 90px 0 90px 0;
        height: calc(100vw - 100vh);
        max-height: 586px;
        min-height: 350px;
        .container {
            align-items: flex-start;
            & > h1 {
                text-align: left;
                font-size: 55px;
                line-height: 72px;
                font-weight: 300;
                letter-spacing: 0px;
                color: #404041;
            }
        }
    }
}
