.link-descr-list-section {
    margin-bottom: 81px;
    .container {
        .link-descr-list {
            display: flex;
            flex-direction: column;
            &__item {
                margin-bottom: 68px;
                padding-left: 82px;
                padding-bottom: 64px;
                padding-top: 56px;
                padding-left: 20px;
                background-color: #f7f8f9;
                display: flex;
                flex-direction: column;
                &__title {
                    padding-bottom: 5px;
                    & > h3 {
                        color: #1d1d1e;
                        font-family: Inter;
                        font-size: 35px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 46px;
                    }
                }
                &__subtitle {
                    padding-bottom: 43px;
                    & > span {
                        color: #1d1d1e;
                        font-family: Inter;
                        font-size: 30px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 47px;
                        text-transform: uppercase;
                    }
                }
                &__link {
                    display: flex;
                    align-items: center;
                    & > a {
                        display: flex;
                        color: #062336;
                        font-family: Inter;
                        font-size: 22px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 25px;

                        &::after {
                            content: url(../img/east_black.svg);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
