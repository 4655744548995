.accordion-section {
    margin-bottom: 81px;
    @media (max-width: 1024px) {
        margin-bottom: 81px;
    }
}
.accordion-wrapper {
    flex-wrap: wrap;
    gap: 4rem;
    display: flex;
    max-width: 900px;
    margin: 0 auto;
    @media (max-width: 1024px) {
        gap: 0;
    }
    @media (max-width: 890px) {
        flex-direction: column;
    }
    & > .h1,.h2,.h3,.h4,.h5,.h6 {
        padding-bottom: 30px; // for the future make this dynamic somehow
    }
    & > .span,.p {
        padding-bottom: 15px; // for the future make this dynamic somehow
    }
    & > .left {
        text-align: left;
    }
    & > .center {
        text-align: center;
    }
    & > .right {
        text-align: right;
    }
}
.accordion {
    flex: 1;
    // margin-top: 60px;
    &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: 890px) {
            margin-right: 0;
        }
    }
    @media (max-width: 1024px) {
        margin-top: 15px;
    }
    .accordion__item {
        margin-bottom: 6px;
        .accordion__item-trigger {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            background: $white;
            padding: 10px 0 30px 0;
            border-bottom: solid 1.2px $graysecond;
            &:not(:last-child) {
                margin-bottom: 30px;
                @media (max-width: 1024px) {
                    margin-bottom: 0px;
                    padding: 10px 0 15px;
                }
            }
            .trigger__num {
                margin-right: 10px;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.035em;
                color: $white;
            }
            .trigger__title {
                color: $woodsmoke;
                
                & > h3 {
                    font-size: 25px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 35px;
                    @media (max-width: 1600px) {
                        font-size: 25px;
                        line-height: 35px;
                    }
                    @media (max-width: 590px) {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
            .trigger__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 0 13px;
                @media (max-width: 360px) {
                    padding: 0 10px 0 13px;
                }
                i {
                    color: $primary;
                    font-size: 32px;
                    font-weight: 300;
                    transform: rotate(0deg);
                    animation: fade 0.3s ease;
                    transition: all 0.3s;
                }
            }
        }
        .accordion__item-content {
            max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
            &:not(:last-child) {
                margin-bottom: 30px;
                @media (max-width: 1024px) {
                    margin-bottom: 15px;
                }
            }
            .content__text {
                margin-top: 6px;
                padding: 11px 0px;
                color: $woodsmoke;
                box-sizing: border-box;
                @media (max-width: 590px) {
                    & > h4 {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
                & h2,h3,h4,h5,h6,p {
                    padding-bottom: 20px;
                }
                & p > img {
                    margin-top: 20px;
                    width: 250px;
                    height: auto;
                    object-fit: cover;
                    @media (max-width: 590px) {
                        width: 100%;
                    }
                }
                // & > p {
                //     font-family: Inter;
                //     font-size: 16px;
                //     letter-spacing: 0;
                //     line-height: 30px;
                //     @media (max-width: 1024px) {
                //         font-family: Inter;
                //         font-size: 13px;
                //         letter-spacing: 0;
                //         line-height: 20px;
                //     }
                // }
                ul {
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 32px;
                    @media (max-width: 1024px) {
                        font-size: 13px;
                        letter-spacing: 0;
                        line-height: 20px;
                    }
                    & > li {
                        position: relative;
                        padding-left: 30px;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            height: 8px;
                            width: 8px;
                            background-color: $black;
                            border-radius: 50%;
                            transform: translate(0px, -50%);
                        }
                    }
                }
            }
        }
    }
}
.accordion__item.active {
    .accordion__item-content {
        // display: block;
        opacity: 1;
    }
    .accordion__item-trigger {
        .trigger__icon {
            i {
                transform: rotate(90deg);
                animation: fade 0.3s ease;
                transition: all 0.3s;
            }
        }
    }
}
