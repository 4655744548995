#lottie {
    overflow: hidden;
    position: absolute;
    top: -70px;
    left: 0;
    z-index: -1;
    @media (max-width: 890px) {
        transform: rotate(90deg);
        top: 70px;
    }
    @media (max-width: 490px) {
        transform: rotate(90deg);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
    }
}
#lottie > svg {
    width: 100vw !important;
    height: 100vh !important;
    @media (max-width: 890px) {
        height: initial !important;
    }
    @media (max-width: 490px) {
        height: initial !important;
        transform: scale(1.7) !important;
    }
}
