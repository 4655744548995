.image-section {
    padding-bottom: 90px;
    @media (max-width: 490px) {
        padding-bottom: 60px;
    }
    .container {
        .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            & > img {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                max-height: 500px;
                object-fit: contain;
                @media (max-width: 490px) {
                    max-height: 300px;
                }
            }
        }
    }
}
