.swiper {
    width: 100%;
    height: 100%;
}
.testimonials__list__item .swiper-slide {
    text-align: center;
    font-size: 18px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-prev {
    position: absolute;
    left: 0;
    top: calc(50% + 70px);
    @include bg(contain, center);
    background-image: url(../img/btn_slider_left.svg);
    height: 20px;
    width: 20px;
    cursor: pointer;
    z-index: 9999;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: all 0.3s;
    &:hover {
        opacity: 0.6;
    }
    &:active {
        opacity: 0.8;
    }
    @media (max-width: 1350px) {
        left: 20px;
    }
    @media (max-width: 490px) {
        display: none;
    }
}
.swiper-button-next {
    position: absolute;
    right: 0;
    top: calc(50% + 70px);
    @include bg(contain, center);
    background-image: url(../img/btn_slider_right.svg);
    height: 20px;
    width: 20px;
    cursor: pointer;
    z-index: 9999;
    transform: translate(50%, -50%);
    opacity: 1;
    transition: all 0.3s;
    &:hover {
        opacity: 0.6;
    }
    &:active {
        opacity: 0.8;
    }
    @media (max-width: 1350px) {
        right: 20px;
    }
    @media (max-width: 490px) {
        display: none;
    }
}
.swiper-button-prev2 {
    position: absolute;
    left: 70px;
    top: calc(50% + 50px);
    @include bg(contain, center);
    background-image: url(../img/prev-slider.svg);
    height: 42px;
    width: 25px;
    cursor: pointer;
    z-index: 9998;
    transform: translate(0%, -50%);
    opacity: 1;
    transition: all 0.3s;
    &:hover {
        opacity: 0.6;
    }
    &:active {
        opacity: 0.8;
    }
    @media (max-width: 1350px) {
        left: 20px;
    }
    @media (max-width: 768px) {
        top: calc(0% + 410px);
        transform: translate(15px,-50%);
        height: 30px;
        width: 13px;
    }
}
.swiper-button-next2 {
    position: absolute;
    right: 70px;
    top: calc(50% + 50px);
    @include bg(contain, center);
    background-image: url(../img/next-slider.svg);
    height: 42px;
    width: 25px;
    cursor: pointer;
    z-index: 9998;
    transform: translate(0%, -50%);
    opacity: 1;
    transition: all 0.3s;
    &:hover {
        opacity: 0.6;
    }
    &:active {
        opacity: 0.8;
    }
    @media (max-width: 1350px) {
        right: 20px;
    }
    @media (max-width: 768px) {
        top: calc(0% + 410px);
        transform: translate(-15px,-50%);
        height: 30px;
        width: 13px;
    }
}
.swiper-button-disabled {
    opacity: 0.2;
}