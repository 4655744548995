section.home-top-section {
    padding-top: 50px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
    overflow: hidden;
    height: calc(100vh - 100px);
    max-height: 818px;
    @media (min-width: 1600px) {
        height: calc(100vh - 103px);
    }
    @media (max-width: 490px) {
        height: initial;
    }
    .container {
        flex-direction: column;
        display: flex;
        height: 100%;
        .top-section__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex: 1 1 70%;
            color: $white;
            @media (max-width: 490px) {
                flex: 1;
            }
            &__subtitle {
                padding-bottom: 13px;
                & > span {
                    text-transform: uppercase;
                    font-size: 55px;
                    font-weight: 400;
                    letter-spacing: 0;
                    line-height: 55px;
                    @media (max-width: 490px) {
                        font-size: 28px;
                    }
                }
            }
            &__title {
                max-width: 90%;
                padding-bottom: 70px;
                @media (max-width: 490px) {
                    max-width: 100%;
                    padding-bottom: 30px;
                }
                & > h1 {
                    font-size: 55px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 55px;
                    @media (max-width: 590px) {
                        font-size: 40px;
                        line-height: 50px;
                    }
                }
            }
            &__description {
                max-width: 930px;
                padding-bottom: 81px;
                & > span {
                    font-size: 28px;
                    letter-spacing: 0;
                    line-height: 40px;
                    @media (max-width: 590px) {
                        font-size: 24px;
                        letter-spacing: 0;
                        line-height: 35px;
                    }
                }
            }
        }
    }
    .container-full {
        position: absolute;
        z-index: -1;
        bottom: 50px;
        left: 0;
        right: 0;
        .top-section__soclink {
            &__list {
                display: flex;
                flex-direction: column;
                gap: 20px;
                &__item {
                    & > a {
                        & > i {
                            color: $white;
                        }
                    }
                    &:hover {
                        & > a {
                            & > i {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}
section.direction-section {
    margin-top: -120px;
    padding-bottom: 60px;
    @media (max-width: 490px) {
        margin-top: -60px;
    }
    .container {
        .direction-wrapper {
            width: 100%;
            flex: 1 1 30%;
            padding-bottom: 20px;
            padding-top: 40px;
            .top-section__direction-list {
                display: flex;
                justify-content: center;
                flex: 1 1 30%;
                flex-wrap: wrap;
                margin-left: -2rem;
                margin-bottom: -2rem;
                @media (max-width: 1265px) {
                    margin-left: -1rem;
                }
                @media (max-width: 891px) {
                    margin-left: 0;
                }
                &__item {
                    min-width: 390px;
                    background-color: $new_light_blue;
                    width: calc(1 / 3 * 100% - 2rem);
                    margin-left: 2rem;
                    margin-bottom: 2rem;
                    padding: 35px 40px 45px 40px;
                    opacity: 0;
                    transform: translateY(150%);
                    animation: ani 3s forwards;
                    transition: box-shadow 0.5s;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    @media (max-width: 1265px) {
                        min-width: 300px;
                        margin-left: 1rem;
                        width: calc(1 / 3 * 100% - 1rem);
                    }
                    @media (max-width: 891px) {
                        width: 100%;
                        margin-left: 0;
                    }
                    @media (max-width: 490px) {
                        min-width: 270px;
                        padding: 20px 15px;
                    }
                    @keyframes ani {
                        40% {
                            opacity: 0;
                            transform: translateY(150%);
                        }
                        100% {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                    &__descr {
                        padding-bottom: 30px;
                        @media (max-width: 490px) {
                            padding-bottom: 10px;
                        }
                        & > p {
                            color: $woodsmoke;
                        }
                    }
                    & > h3 {
                        color: $woodsmoke;
                        padding-bottom: 20px;
                    }
                    & > a {
                        display: flex;
                        align-items: center;
                        font-family: Inter;
                        font-size: 22px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 23px;
                        color: $primary;
                        @media (max-width: 590px) {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 30px;
                        }
                        & > i {
                            padding-left: 10px;
                            padding-top: 2px;
                        }
                    }
                    &:hover {
                        box-shadow: 5px 5px 30px -2px rgba(255, 255, 255, 0.4);
                        transition: box-shadow 0.5s;
                    }
                }
            }
        }
    }
}
