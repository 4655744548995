section.about-us-section {
    position: relative;
    padding-top: 88px;
    padding-bottom: 88px;
    &::after {
        content: "";
        @include bg(contain, right);
        background-image: url(../img/about_us_bg_line.svg);
        height: 31px;
        width: calc(100% / 2 - 40px);
        position: absolute;
        right: 0px;
        bottom: 88px;
    }
    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .about-us__img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 2 - 40px);
            & > img {
                width: 100%;
                object-fit: cover;
            }
        }
        .about-us__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: calc(100% / 2 - 40px);
            & > h3 {
                font-family: Inter;
                font-size: 35px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 46px;
                padding-bottom: 37px;
            }
            &__descr {
                padding-bottom: 81px;
                & > p {
                    font-family: Inter;
                    font-size: 22px;
                    letter-spacing: 0;
                    line-height: 42px;
                }
            }
            & > a {
                margin-bottom: 84px;
            }
        }
    }
}
