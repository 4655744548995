.form-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    &:has(> input[value="whitepaper form"]:first-child) {
        gap: 0;
    }
    &:has(> input[value="whitepaper"]:first-child) {
        gap: 0;
    }
    .form-row {
        // display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .form-col {
            display: grid;
            align-items: center;
            gap: 20px;
            width: 100%;
            min-width: 280px;
            .wpcf7-form-control-wrap {
                width: 100%;
                position: relative;
            }
            span[data-name="swprivacy"] {
                margin-top:-40px;
            }
            .wpcf7-form-control-wrap > input,
            .wpcf7-form-control-wrap > textarea,
            .wpcf7-form-control-wrap > select {
                padding: 8px;
                border: none;
                color: #0c0d0e;
                font-family: Inter;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 30px;
                color: $woodsmoke;
                width: 100%;
                background-color: #fff;
                &:focus {
                    border: none;
                    outline: 0;
                    background-color: #fff;
                    &::placeholder {
                        color: rgba(12, 13, 14, 0.4);
                    }
                }
                @media (max-width: 590px) {
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 26px;
                }
                &::placeholder {
                    color:#0C0D0E;
                }
            }
            p {
                font-family: Inter;
                font-size: 11px;
                letter-spacing: 0;
                line-height: 14px;
                color: $white;
            }
        }
    }
    textarea.wpcf7-textarea {
        height: 150px;
        resize: none;
    }
    span.wpcf7-form-control-wrap {
        span.wpcf7-form-control.wpcf7-checkbox.wpcf7-validates-as-required {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 20px;
        }
    }
    div[data-class="wpcf7cf_group"] {
        display: flex;
        flex-direction: column;
        gap: 20px;
        & > p {
            color: #0C0D0E !important;
        }
    }
}
.screen-reader-response {
    display: none;
}
.wpcf7-form-control-wrap.your-email {
    & > .wpcf7-not-valid-tip {
        @media (max-width: 490px) {
            min-width: 180px;
            transform: translate(-50%, -10px);
            transform: translate(-50%, -46px);
            text-align: right;
        }
    }
}
/* here to change contact form alert style */
.wpcf7-not-valid-tip {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -24px;
    font-size: 14px;
    color: $primary;
    min-width: 100px;
    @media (max-width: 490px) {
        bottom: -25px;
    }
}
.special-container-onecol {
     // alert msgs only for footer form
     .wpcf7-not-valid-tip {
        top: 87%;
        left: auto;
        font-weight: 500;
        letter-spacing: 0.026em;
        color: #fff;
        font-size: 10px;
        padding: 3px 13px;
        // border-radius: 25px;
        background: #eb2638;
        width: fit-content;
        height: 14px;
        line-height: 8px;
    }
    .wpcf7-response-output {
        font-weight: bold;
        color: $black; 
        font-size: 14px;
        position: relative;
        bottom: auto;
        padding-top: 30px;
        @media (max-width: 590px) {
            display: block;
            position: relative;
            margin-bottom: 40px;
        }
    }
}
.special-container-img {
    // alert msgs only for footer form
    .wpcf7-not-valid-tip {
       top: 87%;
       left: auto;
       font-weight: 500;
       letter-spacing: 0.026em;
       color: #fff;
       font-size: 10px;
       padding: 3px 13px;
       // border-radius: 25px;
       background: #eb2638;
       width: fit-content;
       height: 14px;
       line-height: 8px;
   }
   .wpcf7-response-output {
       font-weight: bold;
       color: $black; 
       font-size: 14px;
       position: relative;
       bottom: auto;
       padding-top: 30px;
       @media (max-width: 590px) {
           display: block;
           position: relative;
           margin-bottom: 40px;
       }
   }
}
.special-container {
    // alert msgs only for footer form
    .wpcf7-not-valid-tip {
       top: 87%;
       left: auto;
       font-weight: 500;
       letter-spacing: 0.026em;
       color: #fff;
       font-size: 10px;
       padding: 3px 13px;
       // border-radius: 25px;
       background: #eb2638;
       width: fit-content;
       height: 14px;
       line-height: 8px;
   }
   .wpcf7-response-output {
       font-weight: bold;
       color: $black; 
       font-size: 14px;
       position: relative;
       bottom: auto;
       padding-top: 30px;
       @media (max-width: 590px) {
           display: block;
           position: relative;
           margin-bottom: 40px;
       }
   }
}
.footer-contactform__form {
    // alert msgs only for footer form
    .wpcf7-not-valid-tip {
        top: 75%;
        left: auto;
        font-weight: 700;
        color: #fff;
        font-size: 12px;
        padding: 3px 13px;
        border-radius: 25px;
        background: #eb2638;
        width: 155px; // fallback
        width: fit-content;
        height: 20px; // fallback
        height: fit-content;
    }
    .wpcf7-response-output {
        font-weight: bold;
        color: #ffe699; 
        font-size: 14px;
        @media (max-width: 590px) {
            display: block;
            position: relative;
            margin-bottom: 40px;
        }
    }
}
form.wpcf7-form {
    position: relative;
}
/* here to change contact form alert style */
.wpcf7-response-output {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -55px;
    text-align: center;
    font-size: 14px;
    color: $primary;
}

/* custom checkbox styling for contact form 7 checkbox */
span.wpcf7-list-item {
    display: inline-block;
    margin: 0;
}
.wpcf7 .wpcf7-list-item {
    display: block;
    // margin-bottom: 10px;
}
.wpcf7-checkbox label {
    position: relative;
    cursor: pointer;
    & > span {
        @media (max-width: 590px) {
            font-size: 12px;
            line-height: 20px;
        }
    }
}
.wpcf7-checkbox input[type="checkbox"] {
    /*position: relative;*/
    position: absolute;
    visibility: hidden;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
}
.wpcf7-checkbox input[type="checkbox"] + span:before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 0;
    height: 25px;
    width: 25px;
    top: 50%;
    left: 0px;
    background-color: #fff;
    // border: 2px solid $primary;
    transform: translate(0, -50%);
    @media (max-width: 490px) {
        height: 20px;
        width: 20px;
        top: 57%;
    }
}
.wpcf7-checkbox input[type="checkbox"] + span:after {
    display: block;
    position: absolute;
    content: "\2713";
    height: 25px;
    width: 25px;
    top: 50%;
    left: 0;
    color: #fff;
    background-color: #EB2638;
    visibility: hidden;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    transform: translate(0, -50%);
    @media (max-width: 490px) {
        height: 20px;
        width: 20px;
        line-height: 19px;
    }
}
.wpcf7-checkbox input[type="checkbox"]:checked + span:before {
    background: transparent;
}
.wpcf7-checkbox input[type="checkbox"]:checked + span:after {
    visibility: visible;
}
.wpcf7-list-item-label {
    margin-left: 40px;
    display: inline-block;
}
@media (max-width: 490px) {
    .wpcf7-form-control.wpcf7-checkbox > span > label > span {
        padding-left: 30px;
    }
}
.wpcf7-spinner {
    display: none;
}
.wpcf7-form-control.wpcf7-checkbox {
    & > span {
        & > label {
            & > span {
                padding-left: 40px;
            }
        }
    }
}

/* 
custom checkbox styling for contact form 7 checkbox ACCEPTANCE field 
 
add the following line to the additional settings tab
inside each form if acceptance field is used:

acceptance_as_validation: on 

*/

.wpcf7-acceptance label {
    position: relative;
    cursor: pointer;
    & > span {
        margin-top: 30px;
        @media (max-width: 590px) {
            font-size: 12px;
            line-height: 20px;
            margin-top: 26px;
        }
    }
}
.wpcf7-acceptance input[type="checkbox"] {
    /*position: relative;*/
    position: absolute;
    visibility: hidden;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
}
.wpcf7-acceptance input[type="checkbox"] + span:before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 0;
    height: 25px;
    width: 25px;
    top: 50%;
    left: 0px;
    background-color: #fff;
    // border: 2px solid $primary;
    transform: translate(0, -50%);
    @media (max-width: 490px) {
        height: 20px;
        width: 20px;
        top: 57%;
    }
}
.wpcf7-acceptance input[type="checkbox"] + span:after {
    display: block;
    position: absolute;
    content: "\2713";
    height: 25px;
    width: 25px;
    top: 50%;
    left: 0;
    visibility: hidden;
    font-size: 18px;
    color: #fff;
    background-color: #EB2638;
    text-align: center;
    line-height: 25px;
    transform: translate(0, -50%);
    @media (max-width: 490px) {
        height: 20px;
        width: 20px;
        line-height: 19px;
    }
}
.wpcf7-acceptance input[type="checkbox"]:checked + span:before {
    background: transparent;
}
.wpcf7-acceptance input[type="checkbox"]:checked + span:after {
    visibility: visible;
}

@media (max-width: 490px) {
    .wpcf7-form-control.wpcf7-acceptance > span > label > span {
        padding-left: 30px;
    }
}
.wpcf7-spinner {
    display: none;
}
.wpcf7-form-control.wpcf7-acceptance {
    & > span {
        & > label {
            & > span {
                padding-left: 40px;
                // margin-top: 30px;
            }
        }
    }
}

