.glossary_block {
	.glossary_block_desc {
		text-align: center;
		width: 70%;
		margin: 0 auto 5%;
	}
	#a-z {
		float: left;
		width: 100%;
		margin-bottom: 25px;
		display: flex;
		flex-wrap: wrap;
	}
	ul#a-z {
		li {
			flex-grow: 1;
			padding: 1% 0;
			margin: 5px;
			text-align: center;
			text-transform: uppercase;
			opacity: 0.5;
		}
		li.active {
			cursor: pointer;
			opacity: 1;
			border-radius: 50%;
		}
		li.current {
			color: #ff002c;
			font-weight: 700;
			background: #f9f9f9;
		}
	}
	#posts-results {
		li {
			display: none;
			.title {
				width: 40%;
				font-weight: 700;
			}
			.desc {
				width: 60%;
			}
		}
		li.show {
			display: flex;
			background: #f9f9f9;
			width: 100%;
			justify-content: space-between;
			padding: 3%;
			margin: 3% 0;
		}
		a {
			display: block;
			padding: 10px 0;
		}
	}
}
