section.swiss-group-section {
    @include bg(cover, center);
    background-color: $new_light_blue;
    .container {
        display: flex;
        flex-direction: column;
        padding-bottom: 81px;
        padding-top: 85px;
        & > h3 {
            text-align: center;
            color: $woodsmoke;
            padding-bottom: 83px;
            @media (max-width: 490px) {
                padding-bottom: 40px;
                font-size: 28px;
                line-height: 45px;
            }
        }
        .swiss-group-wrapper {
            display: flex;
            justify-content: space-between;
            gap: calc(100vw * 0.056);
            .img-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 700px;
                @media (max-width: 490px) {
                    display: none;
                }
                & > img {
                    width: 100%;
                    object-fit: contain;
                }
            }
            .descr-wrapper {
                display: flex;
                flex-direction: column;
                & > h4 {
                    text-align: left;
                    color: $woodsmoke;
                    padding-bottom: 40px;
                    @media (max-width: 490px) {
                        padding-bottom: 20px;
                    }
                }
                &__text {
                    font-family: Inter;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                    color: $woodsmoke;
                    max-width: 520px;
                    p,
                    span,
                    div {
                        color: $woodsmoke;
                        &:not(:last-child) {
                            padding-bottom: 40px;
                        }
                    }
                    a {
                        text-decoration: none;
                        color: $primary;
                    }
                }
            }
        }
    }
}
