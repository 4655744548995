section.alerting-solution-section {
    padding-top: 80px;
    padding-bottom: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 890px) {
        padding-top: 60px;
        padding-bottom: 40px;
    }
    .container {
        display: flex;
        flex-direction: column;
        & > h3 {
            color: $dark;
            font-family: Inter;
            // font-size: 35px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 46px;
            text-align: center;
            padding-bottom: 84px;
            // @media (max-width: 890px) {
            //     font-size: 26px;
            //     line-height: 40px;
            // }
        }
        .banner-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            @media (max-width: 890px) {
                gap: 0;
            }
            &__item {
                display: flex;
                height: 330px;
                min-width: 300px;
                justify-content: center;
                align-items: flex-end;
                width: calc(100% / 2 - 40px);
                @media (max-width: 890px) {
                    height: 300px;
                }
                @media (max-width: 490px) {
                    height: 250px;
                    min-width: 290px;
                }
                @include bg(cover, center);
                &:nth-child(1) {
                    background-image: url(../img/bann_1.webp);
                }
                &:nth-child(2) {
                    background-image: url(../img/bann_2.webp);
                }
                & > a {
                    margin-bottom: 40px;
                    @media (max-width: 890px) {
                        margin-top: 15px;
                        padding: 10px 20px;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
section.banners-sections {
    padding-top: 50px;
    padding-bottom: 50px;
    .container {
        display: flex;
        flex-direction: column;
        & > h2 {
            color: $dark;
            font-family: Inter;
            font-size: 46px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 65px;
            text-align: center;
            padding-bottom: 81px;
        }
        .banner-list {
            display: flex;
            &__item {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                height: 252px;
                &:not(:last-child) {
                    margin-right: 10px;
                }
                & > img {
                    width: 100%;
                    object-fit: cover;
                }
                & > a {
                    position: absolute;
                    top: 75%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 2;
                    font-size: 20px;
                    min-width: 261px;
                }
                &::before {
                    position: absolute;
                    content: "";
                    opacity: 0.28;
                    background: linear-gradient(180.98deg, #02024e 0%, #eb2638 100%);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                }
            }
        }
    }
}
.alerting-solution-section.card-link-color {
    padding-bottom: 81px;
    padding-top: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    @media (max-width: 890px) {
        padding-bottom: 60px;
    }
    .container {
        .banner-container {
            margin-bottom: -2rem;
            &__card {
                display: flex;
                height: 288px;
                justify-content: center;
                align-items: center;
                width: calc(100% / 2 - 20px);
                background-color: #c8d9f1;
                margin-bottom: 1rem;
                transform: scale(1);
                transition: all 0.5s;
                min-width: 300px;
                @media (max-width: 890px) {
                    width: 100%;
                    margin-left: 0rem;
                    min-width: 270px;
                }
                &:hover {
                    transform: scale(1.01);
                    transition: all 0.5s;
                }
                & > span {
                    color: #024c87;
                    font-size: 31px;
                    line-height: 37px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    @media (max-width: 890px) {
                        font-size: 18px;
                    }
                    & > span {
                        padding-right: 30px;
                        @media (max-width: 890px) {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }
    }
}
