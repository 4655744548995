.dealers_map {
	margin: 81px auto;
	text-align: center;
	h3{
		text-align: center;
	}
	.desc {
		color: $woodsmoke;
		text-align: center;
		padding-top:30px;
		margin-bottom: 8%;
	}
	.dealers_map_shortcode {
		width: 100%;
	}
	.contact-form-wrapper {
		width: 100%;
	}
}
