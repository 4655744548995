.btn-fixed-size {
  width: 200px;
  height: 53px;
}
.primary-btn,
input[type="submit"].primary-btn {
  display: block;
  border-radius: 50px;
  border: solid 1px $primary;
  background-color: $primary;
  color: $white;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  padding: 22px 45px;
  transition: all 0.3s;
   @media (max-width: 590px) {
      font-size: 16px;
      padding: 11px 25px;
    }
  &:hover {
    background-color: $white;
    color: $primary;
    border: solid 1px $primary;
  }
  &:active {
    background-color: $woodsmoke;
    color: $white;
    border: solid 1px $woodsmoke;
  }
  &__main {
    align-self: flex-start;
    @media (max-width: 590px) {
      align-self: center;
    }
  }
  &__with-subtitle {
    margin-top: 40px;
    @media (max-width: 590px) {
      margin-top: 10px;
    }
  }
  &__without-subtitle {
    margin-top: 20px;
    @media (max-width: 590px) {
      margin-top: 10px;
    }
  }
}
input[type="submit"].primary-btn {
  align-self: center;
  margin: 0 auto;
  cursor: pointer;
}
.banner-btn {
  cursor: pointer;
  border-radius: 29px;
  background-color: $white;
  border: solid 1px $white;
  padding: 16px 25px;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  color: $dark;
  transition: all 0.3s;
  min-width: 50%;
  max-width: 400px;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.7;
  }
  &:focus {
    color: $dark;
    background-color: $white;
    border: solid 1px $white;
  }
}

.availability-btn,
input[type="submit"].availability-btn {
  display: block;
  border-radius: 50px;
  border: solid 1px $primary;
  background-color: $primary;
  color: $white;
  font-family: Inter;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  padding: 22px 45px;
  transition: all 0.3s;
  &:hover {
    background-color: $white;
    color: $primary;
    border: solid 1px $primary;
  }
  &:active {
    background-color: $woodsmoke;
    color: $white;
    border: solid 1px $woodsmoke;
  }
  @media (max-width: 590px) {
    font-size: 16px;
    padding: 11px 25px;
  }
}
input[type="submit"].availability-btn {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding: 6px 22px;
}
.back-to-top {
  visibility: hidden;
  position: fixed;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  color: $white;
  border-radius: 50%;
  border: solid 1px $primary;
  height: 69px;
  width: 69px;
  bottom: 100px;
  right: 50px;
  transition: all 0.3s;
  z-index: 99999999;
  opacity: 0;
  @media (max-width: 490px) {
    height: 40px;
    width: 40px;
    right: 15px;
    bottom: 8px;
  }
  & > i {
    font-size: 48px;
    @media (max-width: 490px) {
      font-size: 25px;
    }
  }
  &:hover {
    background-color: $white;
    color: $primary;
  }
  &.visibil {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
  }
}
.search-btn {
  border: none;
  background-color: transparent;
}
.events-btn {
  cursor: pointer;
  display: block;
  border-radius: 50px;
  border: solid 1px $primary;
  background-color: $primary;
  color: $white;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin-bottom: 20px;
  padding: 16px 42px 16px;
  transition: all 0.3s;
  @media (max-width: 490px) {
    font-size: 18px;
    padding: 18px 32px 19px;
  }
  &:hover {
    background-color: $white;
    color: $primary;
  }
  &:active {
    background-color: $woodsmoke;
    color: $white;
    border: solid 1px $woodsmoke;
  }
  &__dark {
    background-color: $white;
    color: $primary;
    &:hover {
      border: solid 1px $primary;
      background-color: $primary;
      color: $white;
    }
  }
}

.card_events-btn {
  cursor: pointer;
  display: flex;
  width: 230px;
  width: fit-content;
  border-radius: 50px;
  border: solid 1px $primary;
  background-color: $primary;
  color: $white;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  padding: 7px 45px;
  margin-top: 20px;
  transition: all 0.3s;
  @media (max-width: 490px) {
    font-size: 18px;
    padding: 18px 32px 19px;
  }
  &:hover {
    background-color: $white;
    color: $primary;
  }
  &:active {
    background-color: $woodsmoke;
    color: $white;
    border: solid 1px $woodsmoke;
  }
  &__dark {
    background-color: $white;
    color: $primary;
    &:hover {
      border: solid 1px $primary;
      background-color: $primary;
      color: $white;
    }
  }
  &__pad-left {
    margin-left: 10px;
  }
}

.share-btn-social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  flex-direction: row;
  @media (max-width: 890px) {
      padding-bottom: 20px;
  }
  h5 {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 30px;
      padding-bottom: 10px !important;
      font-weight: 600;
  }
  &__list {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: space-between;
      list-style: none !important;
      gap: 10px;
      &__item {
          cursor: pointer;
          border: 1px solid $primary;
          background-color: $primary;
          border-radius: 50%;
          width: 37px;
          height: 37px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          padding: 0 !important;
          & > a {
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white;
              & > i {
                  font-size: 20px;
              }
          }
          &:hover {
              background-color: $white;
              transition: all 0.3s;
              & > a {
                  color: $primary;
              }
          }
          &:before {
              display: none;
          }
      }
  }
}

