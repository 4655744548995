section.solution-section {
    .container {
        display: flex;
        flex-direction: column;
        padding-bottom: 81px;
        @media (max-width: 490px) {
            padding-bottom: 50px;
        }
        & > h3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $woodsmoke;
            font-family: Inter;
            // font-size: 35px;
            // letter-spacing: 0;
            // line-height: 46px;
            text-align: center;
            & > span {
                font-weight: 300;
            }
            margin-bottom: 81px;
        }
        .solution-section-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            &__tabs {
                display: flex;
                flex-direction: column;

                .tabs-solution {
                    margin-bottom: 45px;
                    @media (max-width: 490px) {
                        margin-bottom: 30px;
                    }
                    &__list {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: solid 1px $gray;
                        @media (max-width: 490px) {
                            flex-direction: column;
                        }
                        &__item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            position: relative;
                            padding-bottom: 20px;
                            @media (max-width: 490px) {
                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                    padding-bottom: 5px;
                                }
                            }
                            & > .tab-btn {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: none;
                                background-color: transparent;
                                // text-transform: uppercase;
                                transition: all 0.3s;
                                padding: 0;
                                color: $dark;
                                font-family: Inter;
                                font-size: 25px;
                                font-weight: 400;
                                letter-spacing: 0;
                                line-height: 35px;
                                @media (max-width: 1600px) {
                                    font-size: 25px;
                                    line-height: 35px;
                                }
                                @media (max-width: 590px) {
                                    font-size: 18px;
                                    line-height: 28px;
                                }
                                &:hover {
                                    color: $primary;
                                }
                            }
                            &.active {
                                & > .tab-btn {
                                    color: $primary;
                                }
                                &::after {
                                    position: absolute;
                                    content: "";
                                    height: 2px;
                                    width: 100%;
                                    background-color: $primary;
                                    bottom: -1px;
                                    left: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
                &__list {
                    display: flex;
                    flex-direction: column;
                    .tabs-content-item {
                        display: none;
                        &.active {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                        &__descr {
                            padding-bottom: 47px;
                            p,
                            a,
                            div {
                                font-family: Inter;
                                font-size: 22px;
                                letter-spacing: 0;
                                line-height: 42px;
                                color: $woodsmoke;
                            }
                            a {
                                text-decoration: underline;
                            }
                        }
                        & > a.primary-btn {
                            align-self: flex-start;
                        }
                    }
                }
            }
            &__static {
                display: flex;
                align-items: center;
                justify-content: center;
                & > img {
                    max-width: 680px;
                }
            }
        }
    }
}
.ajax-load-post {
    padding-top: 60px;
}
