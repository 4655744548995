.icon-row-section {
    margin-bottom: 81px;
    padding-bottom: 81px;
    padding-top: 100px;
    background-color: $new_light_blue;
    // add section style for title and descr 
    .container_title_descr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > h3 {
            color: $woodsmoke;
            font-family: Inter;
            font-size: 35px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 46px;
            padding-bottom: 47px;
            text-align: center;
            @media (max-width: 490px) {
                font-size: 28px;
                line-height: 38px;
                padding-bottom: 40px;
            }
        }
        .row__sub-title {
            color: $woodsmoke;
            font-family: Inter;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 42px;
            text-align: center;
            padding-bottom: 65px;
            max-width: 90%;
            @media (max-width: 490px) {
                max-width: 100%;
                padding-bottom: 45px;
            }
            & > p {
                @media (max-width: 490px) {
                    font-size: 14px;
                    line-height: 29px;
                }
            }
        }
    }
    .container {
        .icon-row-list {
            display: flex;
            flex-wrap: wrap;
          //  align-items: center;
            justify-content: center;
            margin-left: -1rem;
            margin-bottom: -2rem;
            &__item {
                margin-bottom: 2rem;
                margin-left: 1rem;
                min-width: 280px;
                width: calc(1 / 4 * 100% - 1rem);
                display: flex;
                flex-direction: column;
                align-items: center;
                &__img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 104px;
                    width: 104px;
                    border-radius: 50%;
                    margin-bottom: 38px;
                    @media (max-width: 1400px) {
                        height: 80px;
                        width: 80px;
                    }
                    & > img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 104px;
                        width: 104px;
                        object-fit: contain;
                        // border-radius: 50%;
                        @media (max-width: 1400px) {
                            height: 80px;
                            width: 80px;
                        }
                    }
                }
                &__title {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p {
                        color: $woodsmoke;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 1.2px;
                        line-height: 32px;
                        text-transform: uppercase;
                        @media (max-width: 1600px) {
                            line-height: 26px;
                        }
                        @media (max-width: 1400px) {
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 1.1px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}
