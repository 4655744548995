@media screen and (max-width: 1200px) {
	.title-and-descr-section {
		padding-bottom: 40px;
	}
}
@media screen and (max-width: 1024px) {
	.title-and-descr-section {
		padding-bottom: 40px;
	}
}
@media screen and (max-width: 768px) {
	// section.title-section.flexible-template {
	// 	.container.left-temp {
	// 		> h1 {
	// 			font-size: 36px;
	// 			line-height: 50px;
	// 		}
	// 	}
	// }
	.icon-link-list-section {
		.container {
			> h3 {
				font-size: 36px;
				line-height: 50px;
			}
		}
	}
	.availability-tabs {
		flex: 0 0 100%;
	}
	.availability-contents {
		gap: 0;
	}
}
// @media screen and (max-width: 630px) {
// 	section.card-link-section {
// 		.container {
// 			.card-list__item {
// 				width: calc(100% - 2rem);
// 				margin-left: 0;
// 			}
// 			.card-list__item__descr {
// 				font-size: 14px;
// 				line-height: 30px;
// 				max-width: 100%;
// 			}
// 		}
// 	}
// }
