section.application-section {
    background-color: #f8f9f9;
    position: relative;
    padding-top: 85px;
    padding-bottom: 81px;
    &::after {
        position: absolute;
        content: url(../img/oval_hr.svg);
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        & > h3 {
            text-align: center;
            color: $woodsmoke;
            padding-bottom: 81px;
            width: 100%;
        }
        .content-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 65px;
            .picture-col {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 1 30%;
                min-width: 290px;
                & > img {
                    object-fit: contain;
                    border-radius: 50%;
                    border: none;
                    width: 100%;
                }
            }
            .content-col {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                & > h4 {
                    text-align: left;
                    color: $woodsmoke;
                    padding-bottom: 10px;
                }
                &__sub-title {
                    font-family: Inter;
                    font-size: 22px;
                    text-align: left;
                    letter-spacing: 0;
                    line-height: 42px;
                    color: $woodsmoke;
                    padding-bottom: 15px;
                }
                &__descr {
                    font-family: Inter;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                    color: $woodsmoke;
                    padding-bottom: 30px;
                }
            }
        }
    }
}
