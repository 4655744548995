section.card-section {
    // overflow: hidden;
    margin-bottom: 81px;
    @media (max-width: 1600px) {
        margin-bottom: 81px;
    }
    @media (max-width: 1024px) {
        margin-bottom: 81px;
    }
    @media (max-width: 890px) {
        margin-bottom: 40px;
    }
    .container-full {
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin: 0;
        .card-row-list {
            display: flex;
            flex-wrap: wrap;
            &__item {
                position: relative;
                padding: 66px 60px 88px 60px;
                display: flex;
                flex-direction: column;
                flex: 1;
                background-color: #f8f8f9;
                @media (max-width: 890px) {
                    padding: 28px 20px 29px;
                }
                & > h4 {
                    padding-bottom: 10px;
                    text-transform: uppercase;
                    // font-family: Inter;
                    // font-size: 24px;
                    // font-weight: bold;
                    // letter-spacing: 0;
                    // line-height: 35px;
                    color: $primary;
                }
                .card-descr {
                    max-width: 240px;
                    color: $woodsmoke;
                    p,
                    div,
                    span {
                        font-family: Inter;
                        color: $woodsmoke;
                    }
                    a {
                        color: $primary;
                    }
                }
                &:nth-child(2n) {
                    background-color: $primary;
                    & > h4 {
                        color: $white;
                    }
                    .card-descr {
                        color: $white;
                        p,
                        div,
                        span {
                            color: $white;
                        }
                        a {
                            color: $gray;
                        }
                    }
                }
            }
        }
    }
}
