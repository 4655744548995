header {
  display: flex;
  align-items: center;
  background-color: $white;
  box-shadow: 0 6px 37px -1px rgba(0, 0, 0, 0.07);
  width: 100%;
  // top: -100px;
  transition: none;
  height: 103px;
  @media (max-width: 1230px) {
    min-height: 103px;
  }
  @media (max-width: 590px) {
    min-height: 75px;
  }
  &.sticky {
    position: fixed;
    top: 0;
    z-index: 99;
    transition: none;
    min-height: 75px;
    @media (max-width: 1230px) {
      min-height: 75px;
    }
    @media (max-width: 590px) {
      height: 75px;
    }
    .container-full {
      .navbar-header {
        & > ul.navbar-navi {
          & > li.nav-item {
            // padding: 23px 0 20px 0;
            padding: 43px 0 40px 0;
            transition: none;
          }
        }
      }
    }
  }
  .container-full {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .header-logo {
      // display: flex;
      // flex: 1 1 25%;
      // justify-content: flex-start;
      // align-items: center;
      width: 136px;
      height: 30px;
      // @media (max-width: 1450px) {
      //   // flex: 1 1 10%;
      // }
    }
    .navbar-header {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-width: 490px;
      max-width: 800px;
      &.hidden {
        position: relative;
      }
      @media (max-width: 1230px) {
        display: none;
      }
      ul.navbar-navi {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        min-width: min-content;
        max-width: 881px;
        & > li.nav-item {
          padding: 43px 0 40px 0;
          display: block;
          align-items: center;
          min-height: 103px;
          min-width: 100px;
          position: relative;
          &.active {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              height: 2px;
              width: 100%;
              background-color: #eb2638;
              bottom: 0px;
              left: 0;
            }
          }
          &:hover {
            &::after {
              content: "";
              position: absolute;
              height: 2px;
              width: 100%;
              background-color: #eb2638;
              bottom: 0px;
              left: 0;
            }
          }
          & > a {
            text-transform: uppercase;
            color: #0c0d0e;
            font-family: Inter;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.3px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
    .search-form-open {
      display: flex;
      // flex: 1 1 10%;
      align-items: center;
      justify-content: end;
      background-color: transparent;
      height: 20px;
      width: 20.33px;
      border: none;
      cursor: pointer;
      padding-inline-end: 20px;
      &.hidden {
        visibility: hidden;
      }
      & > i {
        opacity: 0.76;
        color: #0c0d0e;
      }
      &:hover i {
        color: #eb2638;
        opacity: 1;
      }
      &:active i {
        color: #979797;
      }
      @media (max-width: 1450px) {
        flex: 1 1 5%;
      }
    }
    .switcher_header {
      display: flex;
      // flex: 1 1 10%;
      align-items: center;
      min-height: 103px;
      &.hidden {
        visibility: hidden;
      }
      @media (max-width: 1450px) {
        // flex: 1 1 5%;
      }
    }
    .search-form {
      display: none;
      align-items: center;
      gap: 14px;
      border-radius: 39px;
      background-color: #f5f5f5;
      padding: 12px 17px;
      flex: 0 0 0%;
      &.active {
        position: absolute;
        display: flex;
        left: 0;
        right: 5%;
        @media (max-width: 1600px) {
          right: 5%;
        }
        @media (max-width: 1450px) {
          right: 5%;
        }
        @media (max-width: 650px) {
          right: 0;
        }
        @media (max-width: 590px) {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      & > input {
        flex: 1;
        border: none;
        background-color: #f5f5f5;
        color: #404041;
        font-family: Inter;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        &::placeholder {
          color: #404041;
        }
        &:focus {
          border: none;
          outline: 0;
        }
      }
      & > .close-search-form {
        display: flex;
        align-items: center;
        height: 12px;
        width: 12px;
        background-color: #f5f5f5;
        cursor: pointer;
        & > i {
          opacity: 0.76;
          color: #0c0d0e;
        }
        &:hover i {
          color: #eb2638;
          opacity: 1;
        }
        &:active i {
          color: #979797;
        }
      }
      .search-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        height: 20px;
        width: 20.33px;
        border: none;
        & > i {
          opacity: 0.76;
          color: #0c0d0e;
        }
        &:hover i {
          color: #eb2638;
          opacity: 1;
        }
        &:active i {
          color: #979797;
        }
      }
    }

    .header-btn-grup {
      display: flex;
      flex: 0 0 20%;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      @media (max-width: 1230px) {
        display: none;
      }
      .contact-btn {
        flex: 1;
        &-link {
          border: solid 1px #eb2638;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          background-color: #eb2638;
          padding: 14px 35px;
          width: 180px;
          & > span {
            color: #ffffff;
            font-family: Inter;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
            text-align: center;
            transition: all 300ms;
          }
          &:hover {
            background-color: #fff;
            & > span {
              color: #eb2638;
              transition: all 300ms;
            }
          }
          &:active {
            background-color: #d1d1d1;
          }
        }
      }
    }
    .user-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4.3px;
      // flex: 1 1 5%;
      @media (max-width: 1230px) {
        display: none;
      }
    }
  }
}
.support-dropdown {
  position: relative;
  width: 180px;
  border: 1px solid #eb2638;
  border-radius: 50px;
  
  z-index: 9;
  flex: 1;
  &.active {
    border-bottom: none;
    border-radius: 25px 25px 0 0;
  }
  cursor: pointer;
  &__btn {
    padding:  14px 22px;
    &__list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .btn-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        padding-left: 22px;
        & > span {
          color: #eb2638;
          font-family: Inter;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
        }
        & > i {
          color: #eb2638;
          font-size: 22px;
          transition: all 200ms;
          &.active {
            transform: rotate(180deg);
            transition: all 200ms;
          }
        }
      }
      &__items {
        visibility: collapse;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: calc(100% + 2px);
        border-bottom: 1px solid #eb2638;
        border-left: 1px solid #eb2638;
        border-right: 1px solid #eb2638;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        background-color: #ffffff;
        top: 30px;
        padding-top: 65px;
        padding-bottom: 81px;
        gap: 32px;
        padding-left: 22px;
        // transition: all 500ms;
        &.active {
          visibility: visible;
          transition: all 600ms;
          z-index: -1;
        }
        &__item {
          & > a {
            opacity: 0.76;
            color: #0c0d0e;
            font-family: "Open Sans";
            font-size: 15px;
            letter-spacing: 0.3px;
            line-height: 20px;
          }
          &:hover {
            & > a {
              color: $primary;
            }
          }
        }
      }
    }
  }
}