.section-404 {
	.content-404_title {
		font-size: 15px;
		font-weight: 700;
		text-transform: uppercase;
	}
	.content-404_subtitle {
		font-size: 55px;
		font-weight: 400;
		text-transform: capitalize;
		line-height: 70px;
		margin: 5% 0;
	}
	.search_block {
		margin-top: 5%;
	}
	margin: 10% 0;
}
