.video-section {
    position: relative;
    // &::before {
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     -webkit-transform: translate(0,-30%);
    //     -ms-transform: translate(0,-30%);
    //     transform: translate(0,-30%);
    //     content: url(../img/oval_left_icon_section.svg);
    //     @media (max-width: 960px) {
    //             display: none;
    //     }
    // }
    // &::after {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     content: url(../img/oval_right_icon_section.svg);
    //     @media (max-width: 960px) {
    //         display: none;
    //     }
    // }
    .container {
        margin-bottom: 81px;
        &__video-width-small {
            width: 40%;
            & > .container__updated-layout > .container__column > iframe {
                height: 400px;
            }
            @media (min-width:1600px) {
                width: 35%; 
                max-width: 700px;
            }
            @media (max-width: 1280px) {
                width: 55%; 
            }
            @media (max-width: 1024px) {
                width: 100%;
                & > .container__updated-layout > .container__column > iframe {
                    height: 500px;
                }
            }
        }
        &__video-width-medium {
            width: 60%;
            & > .container__updated-layout > .container__column > iframe {
                height: 600px;
            }
            @media (min-width:1600px) {
                max-width: 900px;
            }
            @media (max-width: 1024px) {
                width: 100%;
            }
        }
        &__updated-layout {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            // border: 2px solid green;
            justify-content: center;
        }
        &__align-between {
            justify-content: space-between;
        }     
        &__align-center {
            justify-content: center;
        }     
        &__align-left {
            justify-content: left;
        }     
        &__column {
            display: flex;
            flex-basis: 100%;
            flex: 1;
            flex-direction: row;
            @media (max-width: 590px) {
                flex-direction: column;
            }
            // border: 2px solid red;
            .btn-margin-right{
                margin-right: 1rem;
                @media (max-width: 590px) {
                    flex: 100%;
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }
            .btn-margin-left {
                margin-left: 1rem;
                @media (max-width: 340px) {
                    flex: 100%;
                }
            }
            &-padding {
                padding: 10px 20px;
            }
            & > iframe {
                width: 100%;
                aspect-ratio: 16 / 9;
                @media (max-width: 690px) {
                    width: 100%;
                    max-height: 494px;
                    height: 500px;
                    min-height: 300px;
                }
                @media (max-width: 690px) {
                    width: 100%;
                    max-height: 300px;
                    height: 300px;
                    min-height: 300px;
                }
            }
        }
        // wrap the legacy layout into another div to diferentiate for now
        &__legacy-layout {
            & > iframe {
                width: 100%;
                max-height: 694px;
                height: 90vh;
                min-height: 300px;
                @media (max-width: 690px) {
                    width: 100%;
                    max-height: 494px;
                    height: 500px;
                    min-height: 300px;
                }
                @media (max-width: 690px) {
                    width: 100%;
                    max-height: 300px;
                    height: 300px;
                    min-height: 300px;
                }
            }
        }
    }
    .flex-container-two-col {
        display: flex;
        // used to be align-items: center;
        align-items: start;
        // switch alignment to start to flush heading 
        .flex-col-one {
            flex: 50%;
            & > iframe {
                width: 100%;
                aspect-ratio: 16 / 9;
                @media (max-width: 1024px) {
                    width: 100%;
                    max-height: 560px;
                    height: 560px;
                    min-height: 450px;
                }
                @media (max-width: 690px) {
                    width: 100%;
                    max-height: 300px;
                    height: 300px;
                    min-height: 300px;
                }
            }
        }
        .flex-col-two {
            flex: 50%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 0 2rem;
            // switch to 0 pad on top same left right 
            // used ot be padding-left: 2rem;
        }
        @media (max-width: 1024px) {
            flex-direction: column;
            justify-content: center;
            // flex-direction: column-reverse;
            .flex-col-one {
                flex: 100%;
            }
            .flex-col-two {
                flex: 70%;
                padding-left: 0;
                padding-bottom: 2rem;
            }
        }
    }
    .normal-row {
        flex-direction: row;
        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            content: url(../img/oval_right_icon_section.svg);
            @media (max-width: 960px) {
                display: none;
            }
        }
        @media (max-width: 1024px) {
            flex-direction: column;
            justify-content: center;
            // flex-direction: column-reverse;
        }
    }
    .reverse-row {
        flex-direction: row-reverse;
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            -webkit-transform: translate(0,-30%);
            -ms-transform: translate(0,-30%);
            transform: translate(0,-30%);
            content: url(../img/oval_left_icon_section.svg);
            @media (max-width: 960px) {
                    display: none;
            }
        }
        @media (max-width: 1024px) {
            flex-direction: column;
            justify-content: center;
            // flex-direction: column-reverse;
        }
    }
    .video_youtube_title {
        line-height: 48px;
        @media (max-width: 490px) {
            padding-top: 1rem;
            line-height: 26px;
            font-size: 18px;
        }
    }
    .vtube-margin-tob-bottom {
        margin-top: 0; 
        margin-bottom: 1rem;
        @media (max-width: 590px) {
            margin-top: 1rem;
            margin-bottom: 1.2rem;
        }
    }
    .top-bottom-margin-one-col {
        margin-top: 1rem; 
        margin-bottom: 1rem;
        @media (max-width: 590px) {
            margin-top: 1rem;
            margin-bottom: 1.2rem;
        }
    }
    .video_youtube_description {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 28px;
        padding-bottom: 1.2rem;
        @media (max-width: 590px) {
            font-size: 14x;
        }
    }
    .btn-smaller {
        margin: 15px 0;
        // font-size: 18px;
        // padding: 15px 32px 16px;
    }
    .btn-smaller-er {
        margin: 5px 0;
        // font-size: 16px;
        // padding: 10px 25px 11px;
    }
    
}
.add_ornaments {
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transform: translate(0,-30%);
        -ms-transform: translate(0,-30%);
        transform: translate(0,-30%);
        content: url(../img/oval_left_icon_section.svg);
        @media (max-width: 960px) {
                display: none;
        }
    }
    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: url(../img/oval_right_icon_section.svg);
        @media (max-width: 960px) {
            display: none;
        }
    }
}
