section.open-positions-section {
    margin-bottom: 81px;
    @media (max-width: 490px) {
        margin-bottom: 81px;
    }
    .container {
        display: flex;
        flex-direction: column;
        & > h3 {
            font-family: Inter;
            // font-size: 35px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 46px;
            color: $woodsmoke;
            padding-bottom: 30px;
            text-align: center;
        }
        .open-position-link {
            padding-bottom: 26px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            & > a {
                color: #062336;
                font-family: Inter;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 25px;
                text-align: center;
                opacity: 0.7;
                transition: all 0.3s;
                @media (max-width: 490px) {
                    font-size: 14px;
                    line-height: 20px;
                }
                & > i {
                    color: #062336;
                    font-size: 22px;
                    letter-spacing: 0;
                    line-height: 25px;
                    text-align: center;
                    opacity: 0.7;
                    transition: all 0.3s;
                    @media (max-width: 490px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                &:hover {
                    opacity: 1;
                    & > i {
                        opacity: 1;
                    }
                }
            }
        }
        .position-list {
            display: flex;
            flex-direction: column;
            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 25px 25px 25px 30px;
                background-color: $white;
                box-shadow: 5px 5px 30px -2px rgba(14, 31, 53, 0.2);
                margin-bottom: 45px;
                transform: scale(1);
                transition: all 0.5s;
                @media (max-width: 490px) {
                    padding: 15px;
                    margin-bottom: 20px;
                }
                &__descr {
                    display: flex;
                    flex-direction: column;
                    .position-name {
                        text-transform: uppercase;
                        color: $woodsmoke;
                    }
                    .position-subname {
                        color: #404041;
                    }
                }
                &:hover {
                    transform: scale(1.05);
                    transition: all 0.5s;
                }
            }
            .arrow {
                width: 18.85px;
                height: 18.85px;
                @include bg(contain, center);
                background-image: url(../img/arrow_red_right.svg);
            }
        }
    }
}
