$white: #fff;
$black: #000;
$primary: #eb2638;
$lightGray: #979797;
$woodsmoke: #0c0d0e;
$darkgray: #404041;
$dark: #1d1d1e;
$gray: #dedede;
$graysecond: #d9d9d9;
$gold: #ffaa00;
$blue: #024c87;
$sky: #c8d9f1;
$new_light_blue: #E4ECF9;
