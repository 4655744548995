.list-with-icons-blocks {
	display: flex;
	flex-wrap: wrap;
	max-width: 1000px;
	margin: auto;
}
.list-with-icons-block {
	margin-left: 1rem;
	margin-bottom: 1rem;
	width: calc(1 / 4 * 100% - 1rem);
	p {
		display: flex;
		&:before {
			content: "";
			min-width: 20px;
			min-height: 20px;
			display: inline-flex;
			background-image: url("/wp-content/themes/swissphone/img/check_red_circle.svg");
			background-repeat: no-repeat;
			background-position: center center;
			margin-right: 10px;
		}
	}
}
@media screen and (max-width: 1024px) {
	.list-with-icons-block {
		width: calc(1 / 3 * 100% - 1rem);
	}
}
@media screen and (max-width: 768px) {
	.list-with-icons-block {
		width: calc(1 / 2 * 100% - 1rem);
		p {
			font-size: 14px;
		}
	}
}
@media screen and (max-width: 630px) {
	.list-with-icons-section {
		.container {
			.list-with-icons-blocks {
				.list-with-icons-block {
					> p {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 490px) {
	.list-with-icons-block {
		width: 100%;
	}
}
