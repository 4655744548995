.search-results {
	.pagination {
		width: 100%;
		margin-bottom: 5%;
		.page-numbers {
			display: inline-block;
			text-align: center;
			font-weight: 700;
			color: rgb(29, 29, 30, 0.5);
			font-size: 30px;
			background-color: #f5f5f5;
			padding: 10px;
			margin: 5px;
			max-width: 58px;
			width: 100%;
			border-radius: 50%;
		}
		.page-numbers.current {
			color: #1d1d1e;
		}
	}
	.nav-links {
		width: 100%;
		text-align: center;
	}
}
.search-section {
	margin: 10% 0;
	.search_result {
		div.search_result_post {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 5%;
			margin: 5% 0;
			box-shadow: 5px 5px 30px -2px rgba(14, 31, 53, 0.2);
			position: relative;
			.search_result_post_image {
				flex: 0 0 25%;
				img {
					max-width: 330px;
					width: 100%;
				}
			}
			.search_result_post_text {
				flex: 0 0 100%;
			}
		}
		.search_result_post_text {
			&:after {
				content: "";
				width: 30px;
				height: 30px;
				display: block;
				background: url("../img/arrow_red_right.svg");
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				right: 0;
				top: 45%;
			}
		}
		margin-top: 5%;
		.posts_amount {
			font-weight: 700;
			text-transform: uppercase;
		}
		.post_title {
			font-weight: 700;
			text-transform: uppercase;
			color: #0c0d0e;
		}
		.post_type {
			font-weight: 400;
			text-transform: capitalize;
			color: #404041;
			font-size: 16px;
		}
		.content {
			font-weight: 400;
			color: #0c0d0e;
			margin: 0;
			font-size: 22px;
			line-height: 28px;
			* {
				font-weight: 400;
				color: #0c0d0e;
				margin: 0;
				font-size: 22px;
				line-height: 28px;
			}
		}
		blockquote {
			font-weight: 400;
			color: #0c0d0e;
			margin: 0;
			font-size: 22px;
			line-height: 28px;
		}
	}
	p.desc {
		font-size: 22px;
		margin: 30px 0;
		width: 60%;
	}
}
.search_block {
	.search-form {
		border-radius: 39px;
		background-color: #f5f5f5;
		padding: 12px 17px;
		display: flex;
		align-items: center;
		> input {
			-webkit-box-flex: 1;
			-ms-flex: 1;
			flex: 1;
			border: none;
			background-color: #f5f5f5;
			color: #404041;
			font-family: Inter;
			font-size: 15px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 20px;
			outline: none;
		}
		> .close-search-form {
			display: flex;
			align-items: center;
			height: 12px;
			width: 12px;
			background-color: #f5f5f5;
			cursor: pointer;
		}
	}
}
@media screen and (max-width: 768px) {
	.search-section {
		.search_result {
			div.search_result_post {
				.search_result_post_image {
					flex: 0 0 100%;
				}
				.search_result_post_text {
					flex: 0 0 100%;
				}
			}
			div.search_result {
				.search_result_post_text {
					width: 100%;
				}
			}
			.content {
				font-size: 16px;
				width: 100%;
				* {
					font-size: 16px;
					width: 100%;
				}
			}
			blockquote {
				font-size: 16px;
				width: 100%;
			}
		}
		p.desc {
			font-size: 16px;
			width: 100%;
		}
	}
}
