.tabs-abonnements.content-prod {
    .container {
        .tabs-btn {
            &__item {
                padding: 19px 20px;
                min-width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                @media (max-width: 890px) {
                    padding: 15px 15px;
                    min-width: 150px;
                }
            }
        }
        .tabs-contents {
            &__item {
                // & > h4 {
                //     @media (max-width: 1600px) {
                //         font-size: 28px;
                //         font-weight: 700;
                //         letter-spacing: 0;
                //         line-height: 34px;
                //     }
                // }
                &__card-list {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-bottom: -2rem;
                    margin-left: -2rem;
                    &__link {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: start;
                        background-color: #f7f8f9;
                        padding: 20px 30px 50px 30px;
                        margin-bottom: 2rem;
                        margin-left: 2rem;
                        width: calc(1 / 3 * 100% - 2rem);
                        @media (max-width: 1024px) {
                            width: calc(1 / 2 * 100% - 2rem);
                        }
                        @media (max-width: 675px) {
                            width: calc(100% - 2rem);
                        }
                        &__img {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            & > img {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                object-fit: cover;
                                max-width: 100%;
                                max-height: 260px;
                            }
                        }
                        & > h5 {
                            color: #eb2638;
                            font-size: 22px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 42px;
                            text-align: center;
                            padding-bottom: 10px;
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                        &__descr {
                            color: #0c0d0e;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 30px;
                            text-align: center;
                            padding-left: 15px;
                            padding-right: 15px;
                            // & > p {
                            //     font-family: Inter;
                            //     font-size: 14px;
                            //     letter-spacing: 0;
                            //     line-height: 26px;
                            // }
                        }
                    }
                }
            }
        }
    }
}
