section.partners-section {
    .container {
        display: flex;
        flex-direction: column;
        padding-top: 45px;
        padding-bottom: 81px;
        @media (max-width: 490px) {
            padding-bottom: 40px;
        }
        .partners-title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 50px;
            & > span {
                opacity: 0.4;
                color: $darkgray;
                font-family: Inter;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 29px;
                text-align: center;
            }
            & > h1,
            & > h2,
            & > h3,
            & > h4,
            & > h5 {
                padding-bottom: 40px;
                color: $woodsmoke;
            }
        }
        .swiper-wrapper {
            display: flex;
            align-items: center;
            .partners-logo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:not(:last-child) {
                        padding-right: 10px;
                        @media (max-width: 490px) {
                            margin-bottom: 25px;
                        }
                    }
                    & > img {
                        width: 100%;
                        max-height: 100px;
                        object-fit: contain;
                        object-position: bottom;
                        filter: grayscale(100%);
                        @media (max-width: 490px) {
                            max-width: 100%;
                            max-height: 100px;
                        }
                    }
                    &:hover {
                        & > img {
                            filter: none;
                            -webkit-filter: none;
                            -moz-filter: none;
                            -ms-filter: none;
                            -o-filter: none;
                        }
                    }
                }
            }
        }
    }
}
