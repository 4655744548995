section.join-us-section {
    position: relative;
    background-color: $new_light_blue;
    @include bg(cover, center);
    padding-top: 84px;
    padding-bottom: 84px;
    margin-bottom: 90px;
    @media (max-width: 490px) {
        margin-bottom: 81px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > h3 {
            color: $woodsmoke;
            font-family: Inter;
            // font-size: 35px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 46px;
            padding-bottom: 47px;
            text-align: center;
            @media (max-width: 490px) {
                // font-size: 28px;
                // line-height: 38px;
                padding-bottom: 40px;
            }
        }
        .joun-us__sub-title {
            color: $woodsmoke;
            font-family: Inter;
            font-size: 22px;
            letter-spacing: 0;
            line-height: 42px;
            text-align: center;
            padding-bottom: 45px;
            max-width: 90%;
            @media (max-width: 490px) {
                max-width: 100%;
                padding-bottom: 45px;
            }
            & > p {
                @media (max-width: 490px) {
                    font-size: 14px;
                    line-height: 29px;
                }
            }
        }
        .small-padding_bottom {
            padding-bottom: 15px;
        }
        .no-padding_bottom {
            padding-bottom: 0px;
        }
    }
    .footnotes {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        width: 100%;
        padding: 0px 30px;
        bottom: 30px;
        font-size: 12px;
        line-height: 16px;
        color: $woodsmoke;
        text-align: right;
        font-style: italic;
        @media (max-width: 590px) {
            position: relative;
            padding: 33px 20px 0;
            text-align: center;
            line-height: 1.2rem;
            bottom: 0;
            justify-content: center;
            text-align: center;
        }
    }
    .app-cta-logo {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        @media (max-width: 1024px) {
            flex-wrap: wrap;
            & > a {
                width: fit-content;
            }
        }
        .btn-smaller {
            margin: 30px 0;
            // font-size: 18px;
            // padding: 15px 32px 16px;
        }
        .btn-smaller-er {
            margin: 5px 0;
            // font-size: 16px;
            // padding: 10px 25px 11px;
        }
        .btn-margin-right{
            margin-right: 1rem;
            @media (max-width: 590px) {
                margin-right: 0;
            }
        }
        .btn-margin-left {
            margin-left: 1rem;
            @media (max-width: 590px) {
                margin-left: 0;
            }
        }
    }
}
