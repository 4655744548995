@import "../fonts/font-awesome.min.css";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/base/_01_normalize.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/base/_constants.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/base/_mixins.scss";

@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_acc-img-text-sec.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_accordion.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_animate.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_assistive-styles.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_breadcrumbs-page.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_buttons.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_card-bicolor-col.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_card-link-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_column-bg-font.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_common-styles.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_contact-form-page-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_contact-form.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_date-line.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_dealers-map.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_downloads-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_fonts.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_footer.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_global.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_glossary-block.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-about-us.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-alerting-solution.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-availability-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-join-us.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-news.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-partners-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-solution-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-testimonials.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-top-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-trigger.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_hr-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_icon-link-list.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_icon-row.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_image-text-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_image.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_images-by-col.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_info-blocks.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_infographik.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_link-descr-list.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_list-with-icons-block.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_manager-list-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_map-google.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_mega-menu.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_mobail-header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_open-position.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_other-styles.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_page-content.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_page-hub.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_page-scroll-menu.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_product-list-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_quote.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_search-page-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_section-404.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_simple-menu-header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_svg.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_swiper.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_swiss-group-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_tabs_abnnements.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_tabs-content.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_text_by_two_column.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_text-by-column.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_title-and-descr-ui.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_title-header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_ui-components.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_ui-title-subtitle.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_video.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_woocommerce.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_сontent-round.scss";

@import "/Users/simone/decswp/wp-content/swissphone/src/scss/_local-fonts.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/base/_01_normalize.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/base/_constants.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/base/_mixins.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_acc-img-text-sec.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_accordion.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_animate.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_assistive-styles.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_breadcrumbs-page.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_buttons.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_card-bicolor-col.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_card-link-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_column-bg-font.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_common-styles.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_contact-form-page-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_contact-form.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_date-line.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_dealers-map.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_downloads-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_fonts.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_footer.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_global.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_glossary-block.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-about-us.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-alerting-solution.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-availability-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-join-us.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-news.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-partners-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-solution-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-testimonials.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-top-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_home-trigger.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_hr-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_icon-link-list.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_icon-row.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_image-text-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_image.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_images-by-col.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_info-blocks.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_infographik.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_link-descr-list.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_list-with-icons-block.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_manager-list-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_map-google.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_mega-menu.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_mobail-header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_open-position.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_other-styles.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_page-content.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_page-hub.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_page-scroll-menu.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_product-list-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_quote.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_search-page-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_section-404.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_simple-menu-header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_svg.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_swiper.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_swiss-group-section.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_tabs_abnnements.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_tabs-content.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_text_by_two_column.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_text-by-column.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_title-and-descr-ui.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_title-header.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_ui-components.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_ui-title-subtitle.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_video.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_woocommerce.scss";
@import "/Users/simone/decswp/wp-content/swissphone/src/scss/global/_сontent-round.scss";


@import "../../node_modules/swiper/swiper-vars.scss";
@import "../../node_modules/swiper/swiper";

.container {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1280px;
    @media (min-width: 1600px) {
        max-width: 1350px;
    }
}
.container-full {
    width: 100%;
    max-width: calc(100% - 60px);
    padding: 0 15px;
    margin: 0 auto;
    @media (min-width: 1960px) {
        max-width: 1920px;
    }
    @media (max-width: 1350px) {
        max-width: 1280px;
    }
}
button {
    cursor: pointer;
}
.main {
    flex: 1 0 auto;
    &__margin-top {
        margin-top: 103px;
        @media (max-width: 590px) {
            margin-top: 75px;
        }
    }
}
html,
body {
    height: 100%;
    font-family: Inter;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
    display: flex;
    flex-direction: column;
    &.active {
        overflow: hidden;
    }
}
section {
    flex: 1 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Inter;
    word-break: keep-all;
    // -webkit-hyphens: auto;
    // -moz-hyphens: auto;
    // hyphens: auto;
}
h1 {
    font-size: 48.828px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 70px;
    @media (max-width: 1600px) {
        font-size: 48.828px;
        line-height: 70px;
    }
    @media (max-width: 590px) {
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -0.006em;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }
}
h2 {
    font-size: 39.063px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 60px;
    @media (max-width: 1600px) {
        font-size: 39.063px;
        line-height: 60px;
    }
    @media (max-width: 590px) {
        font-size: 25px;
        line-height: 35px;
    }
}
h3 {
    font-size: 31.25px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 50px;
    @media (max-width: 1600px) {
        font-size: 31.25px;
        line-height: 50px;
    }
    @media (max-width: 590px) {
        font-size: 20px;
        line-height: 30px;
    }
}
h4 {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 35px;
    @media (max-width: 1600px) {
        font-size: 25px;
        line-height: 35px;
    }
    @media (max-width: 590px) {
        font-size: 18px;
        line-height: 28px;
    }
}
h5 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    @media (max-width: 1600px) {
        font-size: 20px;
        line-height: 30px;
    }
    @media (max-width: 590px) {
        font-size: 15px;
        line-height: 25px;
    }
}
h6 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
}
p {
    font-family: Inter;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 400;
    @media (max-width: 1600px) {
        font-size: 16px;
        line-height: 30px;
    }
    @media (max-width: 590px) {
        font-size: 14px;
        line-height: 28px;
    }
}
a {
    color: $primary;
    text-decoration: none;
}
ol {
    margin: 0;
    padding: 0;
}
#map {
    width: 100%;
    height: 70vh;
}
strong {
    font-weight: 700;
}
