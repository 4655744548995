section.infographik-section {
    background-color: #fff;
    @include bg(contain, center);
    background-image: url(../img/circle-static.png);
    padding-bottom: 81px;
    padding-top: 85px;
    margin-bottom: 81px;
    @media (max-width: 768px) {
        background-position: center 350px;
        background-size: 85vw;
    }
    .container {
        position: relative;
        top: -50px;
        padding: 0 130px;
        @media (max-width: 900px) {
            padding: 0;
        }
        & > h3 {
            color: $woodsmoke;
            font-family: Inter;
            // font-size: 35px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 46px;
            text-align: center;
            padding-bottom: 30px;
            max-width: 788px;
            margin: 0 auto;
            @media (max-width: 768px) {
                // font-size: 35px;
                // font-weight: 300;
                // letter-spacing: 0;
                // line-height: 46px;
                text-align: center;
                min-height: 220px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .infographik {
            &__list {
                align-items: center;
                @media (max-width: 768px) {
                    align-items: start;
                    padding-top: 10px;
                }
                &__item {
                    display: flex;
                    flex-direction: column;
                    background-color: transparent;
                    // border: 1px solid red; //temp
                    gap: 20px;
                    
                    padding: 0 20px;
                    .infographik__row {
                        display: grid;
                        grid-template-columns: 270px 1fr;
                        grid-gap: 23px;
                        padding: 30px 0;
                        @media (max-width: 768px) {
                            grid-template-columns: 1fr;
                            padding: 0;
                        }
                        .infographik__col {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            height: fit-content;
                            align-self: center;
                            @media (max-width: 768px) {
                                align-self: flex-start;
                            }
                            &-pb {
                                padding-bottom: 40px;
                            }
                            &-shaddow {
                                background: rgba(255, 255, 255, 0.6);
                                box-shadow: 4.56857px 4.56857px 18.4114px -1.82743px rgba(14, 31, 53, 0.2);
                            }
                            &:first-child {
                                .icon-img {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    min-height: 150px;
                                    &__img {
                                        display: flex;
                                        align-self: end;
                                        justify-content: center;
                                        & > img {
                                            width: 100%;
                                            object-fit: contain;
                                            max-height: 155px;
                                        }
                                    }
                                }
                                .infographik-descr {
                                    padding: 0 0 20px 0;
                                    & > .h3 {
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 18px;
                                        line-height: 25px;
                                        text-align: center;
                                    }
                                    & > p,
                                    span,
                                    div {
                                        color: #1d1d1e;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        // font-weight: 400;
                                        // font-size: 16px;
                                        // line-height: 22px;
                                        text-align: center;
                                    }
                                }
                            }
                            &:last-child {
                                .infographik-cont-box {
                                    display: flex;
                                    align-items: start;
                                    flex-direction: column;
                                    gap: 21px;
                                    padding: 40px 50px;
                                    width: 100%;
                                    @media (max-width: 768px) {
                                        padding: 25px;
                                    }
                                    &__h3{
                                        font-weight: 400;
                                        font-size: 25px;
                                        line-height: 35px;
                                    }
                                    &__descr{
                                        padding-top: 13px;
                                        p {
                                            font-family: 'Inter';
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 30px;
                                        }
                                    }
                                    &__a{
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}