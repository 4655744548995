section.availability-section {
    position: relative;
    background-color: $new_light_blue;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    padding-bottom: 81px;
    margin-bottom: 81px;
    text-align: center;
    @media (max-width: 890px) {
        padding-bottom: 40px;
        margin-bottom: 81px;
    }
    &::after {
        position: absolute;
        content: url(../img/white_oval_bottom_left.svg);
        left: 0;
        bottom: 0;
    }
    &::before {
        position: absolute;
        content: url(../img/white_oval_top_right.svg);
        top: 0;
        right: 0;
        z-index: 0;
    }
    .footnotes-tmd {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        width: 100%;
        padding: 0px 30px;
        bottom: 30px;
        font-size: 12px;
        line-height: 16px;
        color: $woodsmoke;
        text-align: right;
        font-style: italic;
        @media (max-width: 590px) {
            position: relative;
            padding: 33px 20px 0;
            text-align: center;
            line-height: 1.2rem;
            bottom: 0;
            justify-content: center;
            text-align: center;
        }
    }
    .container,
    .container-full {
        position: relative;
        padding-top: 81px;
        // padding-bottom: 106px;
        display: flex;
        flex-direction: column;
        @media (max-width: 890px) {
            padding-top: 42px;
            padding-bottom: 53px;
        }
        & > h3 {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $woodsmoke;
            font-family: Inter;
            // font-size: 35px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 46px;
            margin-bottom: 54px;
            @media (max-width: 890px) {
                padding: 12px 20px;
                // font-size: 25px;
                // font-weight: 700;
                // letter-spacing: 0;
                text-align: center;
                // line-height: 43px;
                margin-bottom: 50px;
            }
        }
        .availability__description {
            color: $woodsmoke;
            padding-bottom: 60px;
            p,
            ul,
            div {
                color: $woodsmoke;
            }
        }
    }
}
.availability-contents {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 82px;
    gap: 20px;
    @media (max-width: 890px) {
        padding-top: 40px;
    }
    &__static {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 49%;
        background-image: url(../img/worldmap.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.availability-tabs {
    flex: 1 1 49%;
    &__item {
        display: none;
        .availability-btn {
            align-self: flex-start;
        }
        &__content {
            max-width: 890px;
            // min-height: 200px;
            p,
            a,
            div {
                color: $woodsmoke;
            }
            h2,
            h3,
            h4,
            h5 {
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 42px;
                color: $woodsmoke;
                padding-bottom: 30px;
            }
            a {
                text-decoration: underline;
            }
        }
        &.active {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 42px;
        }
    }
}
.availability-tabs__navi {
    position: relative;
    @media (max-width: 890px) {
        overflow: hidden;
    }
    &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: $woodsmoke;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        position: absolute;
    }
    &__list {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        @media (max-width: 890px) {
            min-width: 100%;
            overflow-x: scroll;
            justify-content: space-around;
            display: flex;
            flex-wrap: wrap;
            overflow-x: hidden;
            gap: 30px;
        }
        &__item {
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: center;
            padding-bottom: 20px;
            @media (max-width: 890px) {
                min-width: 35%;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .availability-tabs-btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: baseline;
                border: none;
                background-color: transparent;
                &__img {
                    opacity: 1;
                    width: 94px;
                    height: 94px;
                    margin-bottom: 15px;
                    transition: opacity 0.3s;
                    @media (max-width: 890px) {
                        width: 60px;
                        height: 60px;
                        margin-bottom: 30px;
                    }
                    & > img {
                        max-width: 80px;
                        @media (max-width: 890px) {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 60px;
                            height: 60px;
                            object-fit: contain;
                        }
                    }
                }
                h4 {
                    color: $woodsmoke;
                    font-family: Inter;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 28px;
                    // max-width: 90%;
                    @media (max-width: 890px) {
                        font-size: 14px;
                        line-height: 23px;
                        min-width: 35%;
                        word-break: keep-all;
                    }
                }
            }
            &:hover {
                .availability-tabs-btn {
                    &__img {
                        opacity: 0.7;
                        transition: opacity 0.3s;
                    }
                    h4 {
                        color: $primary;
                    }
                }
                &::after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    height: 3px;
                    width: 100%;
                    background-color: $primary;
                }
            }
            &.active {
                &::after {
                    position: absolute;
                    bottom: 0;
                    content: "";
                    height: 3px;
                    width: 100%;
                    background-color: $primary;
                }
                .availability-tabs-btn {
                    // &__img {
                    //     // background-image: url(../img/lock_red.svg);
                    // }
                    & > h4 {
                        color: $primary;
                    }
                }
            }
        }
    }
    // &::after {
    //     position: absolute;
    //     content: "";
    //     background-image: url(../img/availability_line.svg);
    //     background-position: center;
    //     background-repeat: repeat no-repeat;
    //     background-size: contain;
    //     height: 31px;
    //     left: 0;
    //     right: 0;
    //     // bottom: calc(50% + 25px);
    //     z-index: -1;
    //     transform: translate(0, -56%);
    // }
}
