.images-by-col {
    padding-top: 50px;
    padding-bottom: 50px;
    .container {
        .image-list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -1rem;
            margin-bottom: -1rem;
            &__item {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 1rem;
                margin-bottom: 1rem;
                width: calc(1 / 4 * 100% - 1rem);
                @media (max-width: 1600px) {
                    width: calc(1 / 3 * 100% - 1rem);
                }
                @media (max-width: 1024px) {
                    width: calc(1 / 2 * 100% - 1rem);
                }
                @media (max-width: 670px) {
                    width: calc(100% - 1rem);
                }
                & > img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 100%;
                    height: 350px;
                    object-fit: cover;
                    @media (max-width: 1350px) {
                        height: 320px;
                    }
                    @media (max-width: 1024px) {
                        height: 300px;
                    }
                    @media (max-width: 670px) {
                        height: 270px;
                    }
                }
            }
        }
    }
}
