section.footer-section {
    position: relative;
    display: flex;
    background-color: $blue;
    overflow: hidden;
    z-index: 1;
    min-height: 420px;
    .container-full {
        padding-top: 44px;
        padding-bottom: 41px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 20px;
        @media (max-width: 890px) {
            flex-direction: column;
            max-width: 1280px;
        }
        .language-navi {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }
        .footer-navbar {
            display: flex;
            flex: 1;
            flex-direction: column;
            z-index: 2;
            @media (max-width: 890px) {
                flex: initial;
            }
            .footer-navi {
                &__list {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 900px;
                    @media (max-width: 1600px) {
                        max-width: 700px;
                    }
                    @media (max-width: 890px) {
                        flex-direction: column;
                    }
                    &__item {
                        cursor: pointer;
                        min-width: 100px;
                        padding-bottom: 20px;
                        display: flex;
                        &:not(:last-child) {
                            padding-right: 20px;
                        }
                        & > a {
                            display: block;
                            color: $white;
                            font-family: "Open Sans";
                            text-transform: uppercase;
                            font-size: 15px;
                            font-weight: 600;
                            letter-spacing: 0.3px;
                            line-height: 20px;
                            text-align: center;
                            @media (max-width: 890px) {
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                        &.active {
                            & > a {
                                color: $primary;
                                font-family: Inter;
                                font-weight: bold;
                                text-align: center;
                            }
                        }
                        &:hover {
                            & > a {
                                color: $primary;
                                transition: all 0.3s;
                            }
                        }
                    }
                }
            }
        }
        .footer-contactform {
            display: flex;
            flex-direction: column;
            max-width: 550px;
            flex: 1;
            &__title {
                display: flex;
                align-items: center;
                padding-bottom: 20px;
                & > h4 {
                    text-transform: uppercase;
                    color: $white;
                    font-family: Inter;
                    font-size: 15px;
                    font-weight: 800;
                    letter-spacing: 0;
                    line-height: 19px;
                }
            }
            &__form {
                @media (max-width: 890px) {
                    padding-bottom: 35px;
                }
                .form-wrap {
                    gap: 20px;
                    .form-row {
                        .form-col {
                            & > .wpcf7-form-control-wrap > input,
                            & > .wpcf7-form-control-wrap > textarea {
                                color: $white;
                                background-color: transparent;
                                &::placeholder {
                                    color: $white;
                                }
                            }
                            label {
                                display: flex;
                                align-items: center;
                                span {
                                    color: $white;
                                    font-family: Inter;
                                    font-size: 9px;
                                    letter-spacing: 0;
                                    line-height: 11px;
                                }
                            }
                            .wpcf7-list-item-label {
                                display: flex;
                            }
                            .wpcf7-checkbox input[type="checkbox"] + span:before {
                                height: 15px;
                                width: 15px;
                                transform: translate(0, -50%);
                            }
                            .wpcf7-checkbox input[type="checkbox"] + span:after {
                                height: 15px;
                                width: 15px;
                                transform: translate(0, -100%);
                            }
                            input[type="submit"].primary-btn {
                                margin: 0;
                                padding: 10px 25px;
                                font-size: 15px;
                                line-height: 20px;
                                width: 150px;
                            }
                            .wpcf7-form-control-wrap > input,
                            .wpcf7-form-control-wrap > select,
                            .wpcf7-form-control-wrap > textarea {
                                border-bottom: 2px solid $white;
                                color: $white;
                            }
                            .wpcf7-form-control-wrap > select {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                -ms-appearance: none;
                                appearance: none;
                                background: url("../img/arrow_down_form_icon.svg") no-repeat right center;
                                outline: 0;
                                option {
                                    color: $black;
                                }
                            }
                            .wpcf7-checkbox input[type="checkbox"] + span:before {
                                border: 2px solid $white;
                            }
                            & > span {
                                font-family: Inter;
                                font-size: 11px;
                                letter-spacing: 0;
                                line-height: 14px;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 890px) {
            .language-navi {
                flex: initial;
            }
        }
    }
}
footer.footer {
    background-color: $white;
    padding: 10px 0 13px 0;
    flex-shrink: 0;
    .container-full {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 890px) {
            flex-direction: column-reverse;
        }
        .footer-info {
            display: flex;
        }
        .footer-social {
            padding: 10px;
            @media (max-width: 890px) {
                padding-bottom: 20px;
            }
            &__list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                &__item {
                    cursor: pointer;
                    border: 1px solid $primary;
                    background-color: $primary;
                    border-radius: 50%;
                    width: 37px;
                    height: 37px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s;
                    & > a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $white;
                        & > i {
                            font-size: 20px;
                        }
                    }
                    &:hover {
                        background-color: $white;
                        transition: all 0.3s;
                        & > a {
                            color: $primary;
                        }
                    }
                }
            }
        }
        .footer-info {
            display: flex;
            flex-direction: column;
            &__navi {
                @media (max-width: 890px) {
                    padding-bottom: 10px;
                }
                &__list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 15px;
                    padding-bottom: 10px;
                    @media (max-width: 890px) {
                        justify-content: center;
                        gap: 20px;
                        flex-direction: column;
                    }
                    &__item {
                        &:not(:last-child) {
                            padding-right: 7px;
                        }
                        position: relative;
                        & > a {
                            color: $woodsmoke;
                            font-family: Inter;
                            font-weight: bold;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 18px;
                            @media (max-width: 390px) {
                                font-size: 12px;
                            }
                        }
                        &:hover {
                            & > a {
                                opacity: 0.6;
                                transition: all 0.3s;
                            }
                        }
                        &:not(:last-child)::after {
                            position: absolute;
                            content: "";
                            right: -5px;
                            width: 2px;
                            height: 100%;
                            background-color: $woodsmoke;
                            transform: translate(50%, 0);
                            @media (max-width: 890px) {
                                width: 0px;
                                right: calc(7 / 2 * 1px);
                            }
                        }
                    }
                }
            }
            .footer-coperight {
                font-family: Inter;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
                @media (max-width: 890px) {
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                }
            }
        }
    }
}
