section.map-section {
    position: relative;
    @media (max-width: 1450px) {
        margin-bottom: 200px;
    }
    .lable-adress-wrapper {
        visibility: hidden;
        position: absolute;
        opacity: 0;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        max-width: 600px;
        width: 100%;
        background-color: $primary;
        color: $white;
        padding: 65px 20px 60px 80px;
        transition: all 0.5s;
        @media (max-width: 1450px) {
            transform: translate(0, 0);
            top: 100%;
            padding: 20px;
            max-width: 100%;
        }
        @media (max-width: 490px) {
            padding: 10px 10px 10px 10px;
        }
        .lable-adress {
            & > h4 {
                // font-size: 24px;
                // padding-bottom: 10px;
                text-transform: uppercase;
            }
            .description {
                max-width: 240px;
                line-height: 30px;
                & > p,
                & > div,
                & > span {
                    color: $white;
                }
                a {
                    color: $primary;
                }
            }
        }
        &.active {
            visibility: visible;
            opacity: 1;
            transition: all 0.5s;
        }
    }
}
