.info-blocks {
	.info-blocks__item {
		.info-blocks__item__buttons {
			a {
				margin: 10px 0;
			}
			display: flex;
			justify-content: space-evenly;
			flex-wrap: wrap;
			margin-top: 5%;
		}
		border: 4px solid #f5f5f5;
		padding: 5%;
		&:first-child {
			flex: 0 0 100%;
		}
		.info-blocks__item__content {
			&:first-child {
				margin-bottom: 5%;
			}
			text-align: center;
			.info-blocks__item__buttons {
				a {
					font-size: 22px;
					font-weight: 500;
					&:after {
						content: "";
						width: 20px;
						height: 14px;
						display: inline-block;
						position: relative;
						right: -5px;
						top: 2px;
						background-image: url("/wp-content/themes/swissphone/img/long-arrow-right-red.svg");
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center center;
					}
				}
			}
		}
		h3.info-blocks__content__title {
			// font-weight: 700;
			margin-bottom: 20px;
			// font-size: 25px;
		}
		h4.info-blocks__content__title {
			// font-weight: 700;
			margin-bottom: 20px;
			// font-size: 25px;
		}
	}
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	justify-content: space-between;
}
@media screen and (max-width: 768px) {
	.info-blocks {
		.info-blocks__item {
			flex: 0 0 100%;
		}
	}
}
