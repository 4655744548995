.simple-dropdown-menu-tooltip {
    position: absolute;
    visibility: hidden;
    opacity: 1;
    background-color: $white;
    padding: 40px;
    border-radius: 15px;
    transition: none;
    border: 1px solid rgba(205, 208, 227, 0.52);
    &.visibil {
        visibility: visible;
        opacity: 1;
        transition: none;
        z-index: 999999;
    }
    &::before {
        position: absolute;
        z-index: -1;
        content: "";
        // left: 50%;
        bottom: 4px;
        width: 90%;
        background: rgba(6, 16, 88, 0.880873);
        filter: blur(135.914px);
        border-radius: 165px;
        height: 19px;
    }
    & > ul.nav {
        display: flex;
        flex-direction: column;
        & > li.nav-item {
            padding-bottom: 30px;
            display: flex;
            align-items: center;
            & > a.nav-link {
                opacity: 0.76;
                color: #0c0d0e;
                font-family: "Open Sans";
                font-size: 15px;
                letter-spacing: 0.3px;
                line-height: 20px;
                min-width: 200px;
            }
            &:hover {
                & > a {
                    color: $primary;
                }
            }
        }
    }
    &::after {
        position: absolute;
        z-index: -1;
        content: "";
        background-color: transparent;
        width: 100%;
        height: 100%;
        top: -25px;
    }
}
