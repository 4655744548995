section.trigger-section {
    padding-bottom: 40px;
    padding-top: 40px;
    background-color: $new_light_blue;
    margin-bottom: 81px;
    @media (max-width: 1024px) {
        margin-bottom: 81px;
    }
    @media (max-width: 890px) {
        padding-top: 60px;
        padding-bottom: 60px;
        margin-bottom: 81px;
    }
    @media (max-width: 490px) {
        padding-bottom: 40px;
        margin-bottom: 40px;
    }
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > h3 {
            color: $woodsmoke;
            font-family: Inter;
            // font-size: 35px;
            // font-weight: 300;
            // letter-spacing: 0;
            // line-height: 46px;
    
            text-align: center;
            padding-bottom: 40px;
            @media (max-width: 890px) {
                // font-size: 28px;
                // line-height: 38px;
                padding-bottom: 30px;
            }
        }
    }
}
.sub-title-trigger-section {
    color: $woodsmoke;
    font-family: Inter;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 81px;
    @media (max-width: 890px) {
        font-size: 16px;
        line-height: 30px;
        padding-bottom: 45px;
    }
}

.sub-title-trigger-section_mod {
    // color: #fff;
// font-family: Inter;
// font-size: 22px;
// letter-spacing: 0;
line-height: 30px;
text-align: center;
padding-bottom: 81px;
max-width: 90%;
    @media (max-width: 890px) {
        // font-size: 16px;
        // line-height: 30px;
        padding-bottom: 45px;
    }
}
