section.breadcrumbs-section {
    padding: 20px 0 70px 0;
    .container-full {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .breadcrumbs-page {
            text-transform: uppercase;
            font-family: Inter;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 29px;
            text-align: center;
            @media (max-width: 690px) {
                font-size: 10px;
            }
            span {
                & > a {
                    color: $woodsmoke;
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 29px;
                    opacity: 0.42;
                    @media (max-width: 690px) {
                        font-size: 10px;
                    }
                }
                & > i {
                    color: $lightGray;
                    font-size: 14px;
                    padding: 0 2px;
                    @media (max-width: 690px) {
                        font-size: 10px;
                    }
                }
            }
            span {
                font-weight: bold;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 29px;
                color: $primary;
                @media (max-width: 690px) {
                    font-size: 10px;
                }
            }
        }
    }
}
