main.hub-page__main {
    background-color: #024c87;
}
.hub-page-section {
    position: relative;
    z-index: 0;
    overflow: hidden;
    padding-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    min-height: 100vh;
    &::after {
        content: url(../img/top_right_oval_bg.svg);
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        @media (max-width: 690px) {
            display: none;
        }
    }
    &::before {
        content: url(../img/bottom_left_oval_bg.svg);
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        @media (max-width: 690px) {
            display: none;
        }
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .header-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            width: 244px;
            margin-bottom: 200px;
            @media (max-width: 1600px) {
                margin-bottom: 150px;
            }
            @media (max-width: 1024px) {
                margin-bottom: 81px;
            }
            & > img {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                object-fit: contain;
            }
        }
        h1 {
            color: $white;
            padding-bottom: 30px;
            text-align: center;
            justify-content: center;
            display: flex;
            max-width: 100%;
            width: 99%;
            @media (max-width: 490px) {
                font-size: 28px;
            }
        }
        .hub-page__sub-title {
            padding-bottom: 82px;
            & > span {
                color: $white;
                text-align: center;
            }
            @media (max-width: 490px) {
                padding-bottom: 40px;
            }
        }
        .hub-page__select-list {
            padding-bottom: 67px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            @media (max-width: 890px) {
                flex-direction: column;
            }
            select {
                width: 315px;
                height: 51px;
                max-width: 315px;
                border-left: none;
                border-right: none;
                border-top: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                appearance: none;
                background: url("../img/arrow_down_form_icon.svg") no-repeat right center;
                outline: 0;
                border-bottom: 2px solid $white;
                color: $white;
                cursor: pointer;
                &:not(:last-child) {
                    margin-right: 89px;
                    @media (max-width: 890px) {
                        margin-right: 0px;
                        margin-bottom: 20px;
                    }
                }
                @media (max-width: 890px) {
                    width: calc(100% - 1rem);
                }
                & > option {
                    color: $black;
                    max-width: 315px;
                    word-break: break-all;
                    padding: 5px;
                }
            }
        }
        .hub-page__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
            width: 100%;
            .primary-btn {
                max-width: 251px;
                width: 100%;
                @media (max-width: 890px) {
                    font-size: 18px;
                    padding: 12px 25px;
                }
            }
        }
    }
}
