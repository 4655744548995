section.date-line-section {
    margin-bottom: 81px;
    background-color: #f7f8f9;
    @media (max-width: 1600px) {
        margin-bottom: 81px;
    }
    @media (max-width: 1024px) {
        margin-bottom: 81px;
    }
    @media (max-width: 890px) {
        margin-bottom: 81px;
    }
    .container {
        padding-bottom: 81px;
        padding-top: 85px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 490px) {
            margin-bottom: 40px;
            margin-top: 40px;
        }
        & > h3 {
            padding-bottom: 25px;
            color: $woodsmoke;
            text-align: center;
            align-self: center;
        }
        .date-line-descr {
            max-width: 1190px;
            // font-family: Inter;
            // font-size: 22px;
            // letter-spacing: 0;
            // line-height: 42px;
            color: $woodsmoke;
            padding-bottom: 81px;
            align-self: flex-start;
            padding-left: 120px;
            @media (max-width: 490px) {
                padding-left: 0;
            }
            p,
            div,
            span {
                // font-family: Inter;
                // font-size: 22px;
                // letter-spacing: 0;
                // line-height: 42px;
                color: $woodsmoke;
            }
            a {
                color: $primary;
                text-decoration: none;
            }
        }
        .content-wrapper {
            display: none;
            flex-direction: column;
            max-width: 883px;
            width: 100%;
            min-height: 220px;
            & > h4 {
                color: $woodsmoke;
                padding-bottom: 66px;
                text-align: left;
                @media (max-width: 490px) {
                    padding-bottom: 18px;
                }
            }
            .date-line-small-descr {
                color: $woodsmoke;
                font-family: Inter;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 30px;
                p,
                div,
                span {
                    color: $woodsmoke;
                    font-family: Inter;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                    @media (max-width: 490px) {
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 22px;
                    }
                }
                a {
                    color: $primary;
                    text-decoration: none;
                }
            }
            &.active {
                display: flex;
            }
        }
        .date-line-list {
            position: relative;
            display: flex;
            align-items: center;
            padding-bottom: 92px;
            // &::after {
            //     content: url(../img/btn_slider_right.svg);
            //     position: absolute;
            //     right: 0;
            //     top: 108px;
            //     transform: translate(300%, -50%);
            // }
            &__item {
                margin-right: -1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &__date {
                    margin-bottom: 37px;
                    width: 90px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    & > span {
                        color: $woodsmoke;
                        font-family: Inter;
                        font-size: 24px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 29px;
                        transition: all 0.3s;
                    }
                }
                &__oval {
                    position: relative;
                    .oval-out {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.3s;
                        .oval-in {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: $white;
                            border: solid 8px $gray;
                            transition: all 0.3s;
                        }
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        top: 50%;
                        right: -14px;
                        transform: translate(50%, -50%);
                        width: 100%;
                        height: 2px;
                        background-color: $gray;
                        z-index: 1;
                        transition: z-index 0.2s 0.1s ease-out;
                    }
                    &::before {
                        position: absolute;
                        top: 50%;
                        left: -14px;
                        transform: translate(-50%, -50%);
                        content: "";
                        width: 100%;
                        height: 2px;
                        background-color: $gray;
                        z-index: 1;
                        transition: z-index 0.2s 0.1s ease-out;
                    }
                }
                &:hover,
                &.active {
                    .date-line-list__item__date {
                        & > span {
                            color: $primary;
                            font-size: 34px;
                            line-height: 35px;
                            transition: all 0.3s 0.17s ease-out;
                        }
                    }
                    .date-line-list__item__oval {
                        z-index: 1;
                        .oval-out {
                            background-color: $primary;
                            transition: all 0.3s 0.17s ease-out;
                            .oval-in {
                                background-color: $primary;
                                border: solid 5px $primary;
                                transition: all 0.3s 0.17s ease-out;
                            }
                        }
                        &::after {
                            z-index: -1;
                        }
                        &::before {
                            z-index: -1;
                        }
                    }
                }
            }
        }
        .date-slider-wrapper.swiper {
            @media (max-width: 490px) {
                max-height: 180px;
            }
        }
    }
}
