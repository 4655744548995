select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	background: url("../img/down-chevron.svg") no-repeat;
	background-position: 98% center;
	background-size: 15px 15px;
}
select[multiple] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	background: none;
}
table {
	border-collapse: collapse;
	display: block;
	overflow-x: auto;
	td {
		border: 1px solid #d9d9d9;
		padding: 10px;
	}
}
.footer-section {
	.language-navi {
		.wpml-ls-legacy-dropdown {
			.wpml-ls-current-language {
				img.wpml-ls-flag {
					object-fit: cover;
					border-radius: 50%;
				}
				&:hover {
					> a {
						background: none;
						border: none;
						color: #fff;
					}
				}
			}
			.wpml-ls-sub-menu {
				background: #fff;
				bottom: 100%;
				top: unset;
				a {
					color: #1d1d1e;
					&:hover {
						color: #1d1d1e;
					}
				}
			}
			a {
				&:focus {
					background: none;
					border: none;
					color: #fff;
				}
				&:hover {
					background: none;
					border: none;
					color: #fff;
				}
				background: none;
				border: none;
				color: #fff;
			}
		}
		flex: 0 0 100%;
	}
}
@media screen and (max-width: 768px) {
	.footer-section {
		.language-navi {
			flex: 1;
		}
	}
}
@media screen and (max-width: 490px) {
	.wpcf7-checkbox {
		input[type="checkbox"] + span {
			&:after {
				line-height: 29px;
			}
		}
	}
}
