.title-and-descr-section {
    position: relative;
    padding-bottom: 41px;
    @media (max-width: 1024px) {
        padding-bottom: 10px;
    }
    @media (max-width: 890px) {
        padding-bottom: 10px;
    }
    .footnotes-td {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        width: 100%;
        padding: 0px 30px;
        bottom: 30px;
        font-size: 12px;
        line-height: 16px;
        color: #969696;
        text-align: right;
        font-style: italic;
        @media (max-width: 590px) {
            position: relative;
            padding: 33px 20px 0;
            text-align: center;
            line-height: 1.2rem;
            bottom: 0;
            justify-content: center;
            text-align: center;
        }
    }
    .container {
        padding-bottom: 40px;
        .title {
            h2 {
                font-size: 39.063px !important;
                font-weight: 300 !important;
                letter-spacing: 0 !important;
                line-height: 60px !important;
                padding-bottom: 50px;
                @media (max-width: 1600px) {
                    line-height: 60px !important;
                    font-size: 39.063px !important;
                }
                @media (max-width: 590px) {
                    line-height: 35px !important;
                    padding-bottom: 20px !important;
                    font-size: 25px !important;
                    font-weight: 300;
                }
            }
            h3{
                font-size: 31.25px !important;
                font-weight: 300 !important;
                letter-spacing: 0 !important;
                line-height: 50px !important;
                padding-bottom: 50px;
                @media (max-width: 1600px) {
                    line-height: 50px !important;
                    font-size: 31.25px !important;
                }
                @media (max-width: 890px) {
                    line-height: 30px !important;
                    padding-bottom: 20px !important;
                    font-size: 20px !important;
                    font-weight: 300;
                }
            }
            h4{
                font-size: 25px !important;
                font-weight: 400 !important;
                letter-spacing: 0 !important;
                line-height: 35px !important;
                padding-bottom: 50px;
                @media (max-width: 1600px) {
                    line-height: 35px !important;
                    font-size: 25px !important;
                }
                @media (max-width: 890px) {
                    line-height: 28px !important;
                    padding-bottom: 20px !important;
                    font-size: 18px !important;
                    font-weight: 400;
                }
            }
            h5{
                font-size: 20px !important;
                font-weight: 400 !important;
                letter-spacing: 0 !important;
                line-height: 30px !important;
                padding-bottom: 50px;
                @media (max-width: 1600px) {
                    line-height: 30px !important;
                    font-size: 20px !important;
                }
                @media (max-width: 890px) {
                    line-height: 25px !important;
                    padding-bottom: 20px !important;
                    font-size: 15px !important;
                    font-weight: 400;
                }
            }
            span,
            p {
                font-size: 16px !important;
                letter-spacing: 0 !important;
                line-height: 30px !important;
                font-weight: 400 !important;
                padding-bottom: 50px;
                @media (max-width: 1600px) {
                    line-height: 30px !important;
                    font-size: 16px !important;
                }
                @media (max-width: 890px) {
                    line-height: 28px !important;
                    padding-bottom: 20px !important;
                    font-size: 14px !important;
                    font-weight: 400;
                }
            }
        }
        .description {
            // @media (max-width: 1600px) {
            //     padding-left: 80px !important;
            // }
            // @media (max-width: 1024px) {
            //     padding-left: 60px !important;
            // }
            // @media (max-width: 890px) {
            //     padding-left: 0 !important;
            //     font-weight: 300;
            // }
            p {
                padding-bottom: 15px;
                @media (max-width: 1600px) {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                }
                @media (max-width: 890px) {
                    font-size: 14px;
                    line-height: 28px;
                }
            }
            ul {
                // display: flex;
                flex-direction: column;
                list-style: none;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 32px;
                @media (min-width: 1024px) {
                    padding-left: 15px;
                    list-style-type: disc;
                    -webkit-columns: 2;
                    -moz-columns: 2;
                    columns: 2;
                    list-style-position: inside;//this is important addition
                }
                @media (max-width: 1024px) {
                    padding-left: 15px;
                }
                @media (max-width: 590px) {
                    padding-left: 0px !important;
                }
                & > li {
                    position: relative;
                    padding-left: 20px;
                    padding-bottom: 20px;
                    @media (max-width: 1600px) {
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 30px;
                        padding-bottom: 10px;
                        font-weight: 300;
                    }
                    @media (max-width: 890px) {
                        padding-bottom: 0px;
                        font-size: 14px;
                    }
                    &::before {
                        position: absolute;
                        left: 0;
                        top: 12px;
                        content: "";
                        height: 8px;
                        width: 8px;
                        background-color: $black;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
