.page-scroll-menu {
    // display: none;
    display: block;
    // show 
    position: fixed;
    z-index: 99999;
    @media (max-width: 890px) {
        z-index: 9;
    }
    width: 5%;
    top: 30%;
    left: 0;
    border: 0.3px solid $primary;
    opacity: 0.9;
    border-radius: 0 8px 8px 0;
    background-color: $primary;
    box-shadow: 0 32px 48px -2px rgba(14, 31, 53, 0.2);
    padding: 28px;
    transition: all 0.5s;
    @media (max-width: 1380px) {
        padding: 10px;
        width: 3%;
    }
    @media (max-width: 890px) {
        top: 100px;
        // change to 100 from top
        width: 100%;
        border-radius: 0;
        box-shadow: 0 32px 48px -2px rgba(14, 31, 53, 0.2);
        padding: 10px 0 0 0;
    }
    @media (max-width: 590px) {
        top: 75px;
        // change to 100 from top
        width: 100%;
        border-radius: 0;
        box-shadow: 0 32px 48px -2px rgba(14, 31, 53, 0.2);
        padding: 0;
    }
    &__title {
        display: none;
        @media (max-width: 890px) {
            padding-bottom: 5px;
            padding-top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-bottom: 1px solid #3573a2;
            opacity: 0.8;
            box-shadow: 0 32px 48px -2px rgba(14, 31, 53, 0.2);
            & > span {
                color: $white;
                font-family: Inter;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
                text-align: center;
            }
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        @media (max-width: 890px) {
            align-items: center;
            padding: 20px;
            position: absolute;
            z-index: -9;
            transform: translate(-100%, -100%);
        }
        &__item {
            display: flex;
            align-items: center;
            max-width: 250px;
            &:not(:last-child) {
                padding-bottom: 20px;
            }
            .oval {
                height: 16px;
                width: 16px;
                min-width: 16px;
                margin-right: 20px;
                border: solid 3px $white;
                background-color: transparent;
                border-radius: 50%;
                transition: all 0.3s;
                @media (max-width: 1380px) {
                    height: 10px;
                    width: 10px;
                    min-width: 10px;
                    margin-right: 10px;
                }
                @media (max-width: 890px) {
                    display: none;
                }
            }
            & > a {
                color: $white;
                font-family: Inter;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
                text-align: left;
                visibility: hidden;
                display: none;
                transform: translate(-100%, 0);
            }
            &:hover {
                .oval {
                    background-color: $white;
                    transition: all 0.3s;
                }
            }
            &.active {
                .oval {
                    background-color: $white;
                    transition: all 0.3s;
                }
            }
        }
        &.active {
            @media (max-width: 890px) {
                .page-scroll-menu__list__item {
                    & > a {
                        visibility: visible;
                        transition: all 0.3s;
                        transform: translate(0, 0);
                        display: flex;
                        text-align: center;
                    }
                }
                align-items: center;
                position: initial;
                z-index: 9;
                transform: translate(0, 0);
            }
        }
    }
    @media (min-width: 890px) {
        &:hover {
            width: auto;
            transition: all 0.5s;
            .page-scroll-menu__list {
                &__item {
                    & > a {
                        visibility: visible;
                        transition: all 0.3s;
                        transform: translate(0, 0);
                        display: flex;
                    }
                }
            }
        }
    }
}
