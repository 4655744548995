.сontent-round-section {
    margin-bottom: 81px;
    padding-top: 20px;
    @media (max-width: 1250px) {
        margin-bottom: 81px;
        padding-top: 10px;
    }
    @media (max-width: 790px) {
        margin-bottom: 81px;
        padding-top: 10px;
    }
    .container {
        .content-round__list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: -1rem;
            @media (max-width: 790px) {
                flex-direction: column;
                align-items: center;
            }
            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: calc(1 / 3 * 100% - 3rem);
                margin-bottom: 1rem;
                @media (max-width: 790px) {
                    width: 100%;
                }
                &__title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 10px;
                    & > p {
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 24px;
                        font-weight: 600;
                        text-align: center;
                    }
                }
                &__descr {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 20px;
                    & > p {
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 24px;
                        text-align: center;
                        max-width: 350px;
                    }
                }
                &:nth-child(1) {
                    // align-self: flex-end;
                }
                &:nth-child(3) {
                    // align-self: flex-end;
                }
                .img-wrapper {
                    position: relative;
                    @media (max-width: 790px) {
                        display: none;
                    }
                    & > img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-width: 100%;
                        object-fit: contain;
                    }
                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: #062336;
                        font-size: 27.59px;
                        font-weight: 800;
                        letter-spacing: -0.48px;
                        line-height: 35.11px;
                        text-align: center;
                        @media (max-width: 790px) {
                            position: initial;
                        }
                    }
                }
                &:nth-child(1) {
                    display: flex;
                    align-items: flex-end;
                    margin-right: -2rem;
                    padding-bottom: 20px;
                    @media (max-width: 790px) {
                        margin-right: 0;
                    }
                }
                &:nth-child(3) {
                    display: flex;
                    align-items: flex-start;
                    margin-left: -2rem;
                    padding-bottom: 20px;
                    @media (max-width: 790px) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
