.downloads-section {
	.downloads-section_heading {
		// font-weight: 400;
		margin-bottom: 5%;
	}
	.downloads-section_desc {
		padding-left: 5%;
	}
	.downloads-rows {
		margin-top: 5%;
	}
	.downloads-tabs__item {
		background-color: #f7f8f9;
		padding: 3% 5%;
		margin-bottom: 50px;
		.downloads-tabs__item__content__heading {
			font-size: 25px;
			font-weight: 400;
			letter-spacing: 0;
			line-height: 35px;
			@media (max-width: 1600px) {
				font-size: 25px;
				line-height: 35px;
			}
			@media (max-width: 590px) {
				font-size: 18px;
				line-height: 28px;
			}
		}
		.downloads-tabs__item__content__standarts {
			font-weight: 200;
			text-transform: uppercase;
			font-size: 2rem;
			line-height: 3rem;
		}
		.downloads-tabs__item__content__companyname {
			font-weight: 200;
			text-transform: uppercase;
			font-size: 2rem;
			line-height: 3rem;
		}
		.downloads-tabs__item__content__text {
			p:first-child {
				padding-bottom: 20px;
			}
		}
		a {
			color: #062336;
			font-weight: 500;
			font-size:22px;
			text-transform: capitalize;
			margin-top: 10px;
			&:after {
				content: "";
				width: 20px;
				height: 14px;
				display: inline-block;
				position: relative;
				right: -5px;
				top: 2px;
				background-image: url("/wp-content/themes/swissphone/img/long-arrow-black-right.svg");
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
		.downloads-row__item__buttons {
			a {
				&:first-child {
					margin-right: 5%;
				}
			}
			display: flex;
			flex-wrap: wrap;
			margin: 5% 0 0;
		}
		.downloads-row__item__button {
			display: flex;
			flex-wrap: wrap;
			margin: 5% 0 0;
		}
	}
}
@media screen and (max-width: 768px) {
	.downloads-section {
		.downloads-tabs__item {
			.downloads-tabs__item__content__heading {
				font-size: 1.5rem;
				line-height: 2rem;
			}
			.downloads-tabs__item__content__standarts {
				font-size: 1.5rem;
				line-height: 2rem;
			}
			.downloads-tabs__item__content__companyname {
				font-size: 1.5rem;
				line-height: 2rem;
			}
			p {
				font-size: 16px;
				line-height: 29px;
			}
		}
	}
}
