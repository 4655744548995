section.management-section {
    margin-bottom: 81px;
    @include bg(initial, left center);
    background-image: url(../img/oval_person_list_bg.svg);
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > h3 {
            text-align: center;
            max-width: 700px;
            color: $woodsmoke;
            padding-bottom: 81px;
            font-weight: 300;
            font-size: 35px;
            line-height: 46px;
            @media (max-width: 490px) {
                padding-bottom: 35px;
                font-weight: 400;
                font-size: 26px;
                line-height: 38px;
            }
        }
        .persons-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-left: -2rem;
            margin-bottom: -2rem;
            @media (max-width: 490px) {
                flex-direction: column;
                margin-left: 0;
            }
            &__item {
                width: calc(1 / 3 * 100% - 2rem);
                margin-left: 2rem;
                margin-bottom: 2rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-width: 300px;
                @media (max-width: 490px) {
                    width: 100%;
                    margin-left: 0;
                }
                &__img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 40px;
                    @media (max-width: 490px) {
                        margin-bottom: 20px;
                    }
                    & > img {
                        object-fit: contain;
                        height: 281px;
                        width: 281px;
                    }
                }
                &__regal {
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .person-name {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-bottom: 10px;
                        & > span {
                            color: $woodsmoke;
                            font-family: Inter;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 30px;
                            text-align: center;
                            font-weight: bold;
                        }
                    }
                    .person-position {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        & > span {
                            color: $woodsmoke;
                            font-family: Inter;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 30px;
                            text-align: center;
                            font-weight: 300;
                            max-width: 285px;
                            padding: 5px;
                        }
                    }
                }
                // &:not(:last-child) {
                //     margin-right: 57px;
                // }
            }
        }
    }
}
