.column-bg-font-section {
    // add colour and photo to card 
    margin-bottom: 81px;
    @media (max-width: 1600px) {
        margin-bottom: 81px;
    }
    @media (max-width: 1024px) {
        margin-bottom: 81px;
    }
    @media (max-width: 890px) {
        margin-bottom: 40px;
    }
    .container {
        display: flex;
        .column-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -1rem;
            margin-left: -1rem;
            &__item {
                min-width: 500px;
                padding: 40px;
                background-color: $new_light_blue;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                margin-left: 1rem;
                width: calc(1 / 2 * 100% - 3rem);
                @media (max-width: 1100px) {
                    width: 100%;
                    min-width: 290px;
                }
                @media (max-width: 490px) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
                & > h4 {
                    // font-size: 24px;
                    // font-weight: bold;
                    // letter-spacing: 0;
                    // line-height: 40px;
                    color: $woodsmoke;
                    padding-bottom: 20px;
                }
                &__descr {
                    padding-bottom: 20px;
                    color: $woodsmoke;
                    @media (max-width: 490px) {
                        padding-bottom: 30px;
                    }
                    p {
                        // font-size: 18px;
                        // letter-spacing: 0;
                        // line-height: 32px;
                        padding-bottom: 30px;
                        color: $woodsmoke;
                        @media (max-width: 490px) {
                            // font-size: 14px;
                            // letter-spacing: 0;
                            // line-height: 22px;
                            padding-bottom: 20px;
                        }
                    }
                }
                &__btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
            &__item_nopads {
                min-width: 500px;
                padding: 0px;
                background-color: #f7f8f9;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                margin-left: 1rem;
                width: calc(1 / 2 * 100% - 3rem);
                @media (max-width: 1100px) {
                    width: 100%;
                    min-width: 290px;
                }
                @media (max-width: 490px) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
                .img_nopads {
                    // if no link is added use class by itself
                    display:block;
                    width: 100%; 
                    height:100%;
                    min-height: 350px;
                    max-height: 350px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                }
                a > div.img_nopads {
                    display:block;
                    width: 100%; 
                    height:100%;
                    min-height: 350px;
                    max-height: 350px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center center;
                }
                & > div > h4 {
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 40px;
                    color: $dark;
                    padding-bottom: 20px;
                    & > a {
                        color: $dark;
                    }
                }
                &__descr_nopads {
                    padding-bottom: 10px;
                    @media (max-width: 490px) {
                        padding-bottom: 30px;
                    }
                    p {
                        font-size: 18px;
                        letter-spacing: 0;
                        line-height: 32px;
                        padding-bottom: 30px;
                        color: $dark;
                        @media (max-width: 490px) {
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 22px;
                            padding-bottom: 20px;
                        }
                    }
                }
                &__btn_nopads {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }
}
