section.contact-form-section {
    background-color: $white;
    display: flex;
    margin: 10px auto 81px auto;
    @media (max-width: 890px) {
        flex-direction: column;
    }
    .left-column-no-descr {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 20px;
        background-color: $white;
        background-image: url(../img/placeholder-mountains-contact.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 890px) {
            flex-direction: column;
            height: 300px;
        }
    }
    .left-column-w-descr {
        display: flex;
        width: 50%;
        align-items: start;
        justify-content: flex-end;
        position: relative;
        padding-top: 40px;
        @media (max-width: 890px) {
            width: 100%;
            justify-content: center;
             padding: 30px;
        }
        img {
            width: 100%;
            max-height: 350px;
            object-fit: cover;
            padding: 0 50px 30px 0;
            @media (max-width: 890px) {
                padding: 0 0 30px 0;
            }
        }
        .contact-form-descr {
            width: 70%;
            @media (max-width: 890px) {
                width: 100%;
            }
            div,
            span,
            p {
                font-family: Inter;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 30px;
                text-align: left;
                color: $woodsmoke;
                @media (max-width: 1600px) {
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 30px;
                }
                @media (max-width: 890px) {
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 30px;
                }
                & > b,
                & > strong {
                    font-weight: bold;
                    color: $black;
                }
            }
            p {
                padding-right: 50px;
                padding-bottom: 30px;
            }
            h2, h3, h4, h5, h6 {
                padding: 0 20px 20px 0;
            }
        }
    }
    // add new descr section below title 
    .special-container {
        display: flex;
        width: 50%;
        justify-content: start;
        @media (max-width: 890px) {
            width: 100%;
            justify-content: center;
            padding: 0;
        }
        .contact-form-wrapper {
            display: flex;
            width: 70%;
            // border: 1px solid red;
            background-color: #E4ECF9;
            flex-direction: column;
            align-items: center;
            padding: 40px 50px;
            @media (max-width: 890px) {
                justify-content: center;
                width: 100%;
                padding: 40px 30px 70px 30px;
            }
            h3 {
                padding-bottom: 40px;
                text-align: center;
            }
            .contact-form-shortcode {
                width: 100%;
                max-width: 890px;
                .support-section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-top: 57px;
                    & > h5 {
                        font-weight: bold;
                    }
                    &__descr {
                        padding-bottom: 27px;
                        & > p {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 32px;
                            text-align: center;
                            max-width: 420px;
                            @media (max-width: 890px) {
                                font-size: 12px;
                                line-height: 22px;
                            }
                        }
                    }
                    &__link {
                        & > a {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 25px;
                            @media (max-width: 890px) {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                }
                input[type="submit"] {
                    margin-top: 30px;
                    width: 100%;
                }
            }
        }
    }
    .special-container-img {
        display: flex;
        width: 100%;
        justify-content: start;
        background-color: #E4ECF9;
        padding: 80px;
        @media (max-width: 890px) {
            padding: 30px;
        }
        .contact-form-wrapper {
            display: flex;
            max-width: 70%;
            // border: 1px solid red;
            flex-direction: column;
            padding: 50px 0;
            align-items: center;
            @media (max-width: 890px) {
                justify-content: center;
                max-width: 100%;
                padding: 30px 0 0 0;
            }
            h3 {
                padding-bottom: 50px;
                @media (max-width: 890px) {
                    text-align: center;
                }
            }
            .contact-form-shortcode {
                width: 100%;
                max-width: 890px;
                .support-section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-top: 57px;
                    & > h5 {
                        font-weight: bold;
                    }
                    &__descr {
                        padding-bottom: 27px;
                        & > p {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 32px;
                            text-align: center;
                            max-width: 420px;
                            @media (max-width: 890px) {
                                font-size: 12px;
                                line-height: 22px;
                            }
                        }
                    }
                    &__link {
                        & > a {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 25px;
                            @media (max-width: 890px) {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                }
                input[type="submit"] {
                    margin-top: 30px;
                    width: 100%;
                }
            }
        }
    }
    .special-container-onecol {
        display: flex;
        width: 100%;
        background-color: #E4ECF9;
        padding: 80px 0 40px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 890px) {
            padding: 50px 0 0 0;
        }
        h3 {
            padding-bottom: 30px;
            @media (max-width: 890px) {
                text-align: center;
            }
        }
        .description-onecol {
            text-align: center;
            max-width: 610px;
            font-size: 16px;
            line-height: 30px;
            p {
                font-size: 16px;
                line-height: 30px;
            }
            @media (max-width: 890px) {
                padding: 0 30px;
            }
        }
        .contact-form-wrapper {
            display: flex;
            width: 580px;
            // border: 1px solid red;
            flex-direction: column;
            padding: 70px 0 20px 0;
            align-items: center;
            @media (max-width: 890px) {
                justify-content: center;
                width: 100%;
                padding: 30px;
            }
            .contact-form-shortcode {
                width: 100%;
                max-width: 890px;
                .support-section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-top: 57px;
                    & > h5 {
                        font-weight: bold;
                    }
                    &__descr {
                        padding-bottom: 27px;
                        & > p {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 32px;
                            text-align: center;
                            max-width: 420px;
                            @media (max-width: 890px) {
                                font-size: 12px;
                                line-height: 22px;
                            }
                        }
                    }
                    &__link {
                        & > a {
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 25px;
                            @media (max-width: 890px) {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                }
                input[type="submit"] {
                    margin-top: 30px;
                    width: 100%;
                }
            }
        }
    }
}
.with-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1620px;
}
