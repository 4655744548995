.tabs-abonnements {
    margin-bottom: 81px;
    @media (max-width: 1024px) {
        margin-bottom: 81px;
    }
    @media (max-width: 490px) {
        margin-bottom: 40px;
    }
    .container {
        display: flex;
        flex-direction: column;
        .tabs-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 36.28px;
            background-color: #eeeeee;
            margin: 0 auto 68px auto;
            &__item {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 30px;
                padding: 19px 85px;
                opacity: 0.25;
                transition: all 0.3s;
                cursor: pointer;
                @media (max-width: 1600px) {
                    font-size: 16px;
                    line-height: 24px;
                }
                @media (max-width: 590px) {
                    font-size: 14px;
                    line-height: 18px;
                }
                &.active {
                    border-radius: 36.28px;
                    background-color: #f7f8f9;
                    opacity: 1;
                    transition: all 0.3s;
                }
                &:hover {
                    opacity: 1;
                    transition: all 0.3s;
                }
            }
        }
        .tabs-contents {
            &__item {
                display: none;
                flex-direction: column;
                &.active {
                    display: flex;
                }
                & > h4 {
                    // font-size: 46px;
                    // font-weight: bold;
                    // letter-spacing: 0;
                    // line-height: 65px;
                    text-align: center;
                    padding-bottom: 29px;
                    // @media (max-width: 1600px) {
                    //     font-size: 38px;
                    //     line-height: 35px;
                    // }
                }
                &__descr {
                    margin-bottom: 81px;
                    @media (max-width: 890px) {
                        margin-bottom: 40px;
                    }
                    p {
                        // font-size: 22px;
                        // letter-spacing: 0;
                        // line-height: 42px;
                        text-align: center;
                        // @media (max-width: 1600px) {
                        //     font-size: 18px;
                        //     letter-spacing: 0;
                        //     line-height: 28px;
                        // }
                        // @media (max-width: 1024px) {
                        //     font-size: 14px;
                        //     line-height: 28px;
                        // }
                    }
                }
                &__card-list {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -2rem;
                    margin-bottom: -27px;
                    padding-bottom: 81px;
                    &__item {
                        visibility: hidden;
                        position: absolute;
                        opacity: 0;
                        z-index: -9;
                        transform: scale(0);
                        transition: all 0.3s;
                        padding-top: 22px;
                        padding-bottom: 33px;
                        padding-left: 29px;
                        padding-right: 29px;
                        background-color: #f7f8f9;
                        min-height: 170px;
                        min-width: 307px;
                        display: flex;
                        flex-direction: column;
                        width: calc(1 / 3 * 100% - 2rem);
                        margin-bottom: 27px;
                        margin-left: 2rem;
                        @media (max-width: 1024px) {
                            width: calc(1 / 2 * 100% - 2rem);
                        }
                        @media (max-width: 675px) {
                            width: calc(100% - 2rem);
                        }
                        &.active {
                            visibility: visible;
                            position: initial;
                            opacity: 1;
                            z-index: 1;
                            transform: scale(1);
                            transition: all 0.3s;
                        }
                        & > h5 {
                            font-size: 24px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 40px;
                            padding-bottom: 10px;
                        }
                        &__descr {
                            p {
                                font-size: 14px;
                                letter-spacing: 0;
                                line-height: 22px;
                            }
                        }
                    }
                }
                &__load-more {
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .primary-btn {
                        min-width: 323px;
                        @media (max-width: 490px) {
                            max-width: 280px;
                        }
                    }
                    &.active {
                        display: flex;
                    }
                }
            }
        }
    }
}
